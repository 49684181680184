import { useNavigation } from '@react-navigation/native';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, StyleSheet, Text, View } from 'react-native';
import * as Yup from 'yup';
import { Button, Checkbox, Input } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { setIsSaveAddressCard } from '../../../redux/getCardsSlice';
import { setPayingAddressDetails } from '../../../redux/oneStopCheckoutSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TUseNavigation } from '../../../types/exportedTypes';
import countryNamesWithCodes, {
	getCommunes,
	wilayas,
} from '../../../utils/countryNamesWithCodes';
import { getCountryLabelFromValue } from '../../../utils/getCountryLabelFromValue';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Dropdown from '../../common/dropdown/Dropdown';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useGetSingleBrandLazy } from '../../../api/brands/useGetSingleBrand';
import { useGetCartItems } from '../../../hooks/handleCheckout/useGetCartItems';
import { useBrandAllowedCurrency } from '../../../hooks/useBrandAllowedCurrency';

export type TAddressBookInitialValues = Yup.InferType<typeof schema>;

const schema = Yup.object({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	address: Yup.string().required('Required'),
	address2: Yup.string(),
	city: Yup.string().required('Required'),
	countery: Yup.string(),
	zipCode: Yup.string().required('Required'),
	phoneNumber: Yup.string().required('Required'),
});

const visitorSchema = Yup.object({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	address: Yup.string().required('Required'),
	address2: Yup.string(),
	city: Yup.string().required('Required'),
	countery: Yup.string(),
	zipCode: Yup.string().required('Required'),
	phoneNumber: Yup.string().required('Required'),
});

const getInitialValue = (data: any) => {
	const initialValues: Omit<TAddressBook, 'createdBy' | '_id'> = {
		name: '',
		firstName: '',
		lastName: '',
		email: '',
		city: data?.country === 'DZ' ? 'Alger' : '',
		countery: data?.country?.toLowerCase(),
		address: '',
		address2: '',
		zipCode: '',
		phoneNumber: '',
	};
	return initialValues;
};

type Props = {
	onSubmit: (values: Omit<TAddressBook, 'createdBy' | '_id'>) => void;
	loading: boolean;
	isEdit: boolean;
	addressBookToEditDetails?: Omit<TAddressBook, 'createdBy'>;
	onCheckout?: boolean;
	createOrderLoading?: boolean;
	payOnDelivery?: (e?: any) => void;
	onChangeWilaya?: (e?: any) => void;
	onChangeCountry?: (e?: any) => void;
	setName?: (val: string) => void;
	setAddress?: (val: string) => void;
	setCity?: (val: string) => void;
	setZipCode?: (val: string) => void;
	setCountry?: (val: string) => void;
	isFullWidth?: boolean;
	isAddAddress?: boolean;
	isNotSaveForLater?: boolean;
	phoneNumber?: string;
	paymentMode?: string;
	total?: number;
	maxOrderForDelivery?: number;
	deliveryFee?: number;
	amountToBePaid?: number;
};

const AddAddressForm = ({
	payOnDelivery,
	onChangeWilaya,
	onChangeCountry,
	onSubmit,
	createOrderLoading,
	paymentMode,
	loading,
	isEdit,
	addressBookToEditDetails,
	onCheckout,
	isFullWidth,
	isAddAddress,
	isNotSaveForLater,
	total,
	maxOrderForDelivery,
	deliveryFee,
	amountToBePaid,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [btnValue, setBtnValue] = useState<'Save' | 'Saved'>('Save');
	const [country, setCountry] = useState([]);
	const [brandData, setBrandData] = useState<TGetAllBrandsData>();
	const [isSaveAddress, setIsSaveAddress] = useState(false);
	const [isAcceptTermsAndCondition, setIsAcceptTermsAndCondition] =
		useState(false);
	const navigation = useNavigation<TUseNavigation>();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const { country: currentCountry } = useAppSelector(state => state.ui);
	// const { setAlert } = useSetAlert();

	const { brandInCartIds } = useGetCartItems();

	const handleSaveAddressOnCheckout = async (
		values: Omit<TAddressBook, 'createdBy' | '_id'>
	) => {
		// const country = getCountryLabelFromValue(values.countery);
		// if (
		// 	country !== 'France' &&
		// 	country !== 'Belgium' &&
		// 	country !== 'Algeria'
		// ) {
		// 	setAlert(
		// 		t(
		// 			'Only shipping addresses in France, Algeria and Belgium can be used to make purchases'
		// 		),
		// 		'danger'
		// 	);
		// } else {
		await dispatch(
			setPayingAddressDetails({
				address: values.address,
				city: values.city,
				countery: values.countery,
				name: `${values?.firstName} ${values?.lastName}`,
				zipCode: values.zipCode,
				phoneNumber: values.phoneNumber,
				email: values?.email,
				firstName: values?.firstName,
				lastName: values?.lastName,
			})
		);
		await dispatch(setIsSaveAddressCard(isSaveAddress));
		if (!isAddAddress) navigation.navigate('Checkout');
		// }
		// setBtnValue('Saved');
	};
	const handleSubmit = async (
		values: Omit<TAddressBook, 'createdBy' | '_id'>
	) => {
		onSubmit(values);
		onCheckout && handleSaveAddressOnCheckout(values);
	};

	const onPressBack = () => {
		navigation.canGoBack()
			? navigation.goBack()
			: navigation.navigate('Home');
	};

	const marginBottom = isLessThanDesktopBase ? 20 : 10;

	const { getSingleBrand } = useGetSingleBrandLazy({
		onCompleted: data => {
			const brandAllowedCountriesData =
				data?.getBrand?.data?.brandAllowedCountries;
			const getCountries = brandAllowedCountriesData?.map(item => {
				const findCountry = countryNamesWithCodes?.find(
					data => data?.value == item?.countryCode
				);
				return findCountry;
			});
			setBrandData(data?.getBrand?.data);
			setCountry((getCountries || []) as any);
		},
	});

	useEffect(() => {
		getSingleBrand(brandInCartIds?.[0]);
	}, []);

	const { findDefaultCountry, findMyCountry, findMyCurrency } =
		useBrandAllowedCurrency({ brand: brandData as TGetAllBrandsData });

	return (
		<Formik
			enableReinitialize
			initialValues={
				isEdit && addressBookToEditDetails
					? addressBookToEditDetails
					: getInitialValue({ country: currentCountry })
			}
			validationSchema={isUserLoggedIn ? schema : visitorSchema}
			onSubmit={values => {
				handleSubmit(values);
			}}
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				values,
				errors,
				touched,
				setFieldValue,
				isValid,
				dirty,
			}) => (
				<>
					<View
						style={[
							{ zIndex: 5000, width: '100%' },
							!isLessThanDesktopBase && {
								width: isFullWidth ? '100%' : '70%',
							},
						]}
					>
						{/* {isUserLoggedIn ? (
							<Input
								value={values.name}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								autoFocus={isSaveAddress}
								onChangeText={handleChange('name')}
								onBlur={handleBlur('name')}
								label={t('Name')}
								error={touched.name ? errors.name : ''}
							/>
						) : ( */}
						<>
							<Input
								// value={values.name}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								autoFocus={isSaveAddress}
								onChangeText={handleChange('firstName')}
								onBlur={handleBlur('firstName')}
								label={t('First Name')}
								error={
									touched.firstName ? errors.firstName : ''
								}
							/>
							<Input
								// value={values.name}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								autoFocus={isSaveAddress}
								onChangeText={handleChange('lastName')}
								onBlur={handleBlur('lastName')}
								label={t('Last Name')}
								error={touched.lastName ? errors.lastName : ''}
							/>
						</>

						{!isUserLoggedIn && (
							<Input
								// value={values.email}
								style={[styles.input]}
								containerStyles={{
									marginBottom: getResponsiveStyle(20),
								}}
								onChangeText={handleChange('email')}
								onBlur={handleBlur('email')}
								label={t('Email')}
								error={touched.email ? errors.email : ''}
							/>
						)}

						<Input
							value={values.address}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(marginBottom),
							}}
							onChangeText={handleChange('address')}
							onBlur={handleBlur('address')}
							label={t('Address')}
							error={touched.address ? errors.address : ''}
						/>

						<Input
							value={values.address2}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(marginBottom),
							}}
							onChangeText={handleChange('address2')}
							onBlur={handleBlur('address2')}
							// label={t('')}
							error={touched.address ? errors.address : ''}
						/>
						{onCheckout ? (
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<View style={{ flex: 1, marginRight: 10 }}>
									{currentCountry === 'DZ' ? (
										<Dropdown
											isSearchable
											dropdownPosition="top"
											options={wilayas}
											labelProps={{
												label: 'Wilaya',
											}}
											onChangeValue={itemSelected => {
												setFieldValue(
													'city',
													itemSelected.value
												);
											}}
											values={{
												label: values.city,
												value: values.city ?? '',
											}}
											error={
												touched.city || touched.city
													? errors.city
													: ''
											}
										/>
									) : (
										<Input
											value={values.city}
											containerStyles={{
												marginBottom:
													getResponsiveStyle(10),
											}}
											onChangeText={handleChange('city')}
											onBlur={handleBlur('city')}
											label={t('City')}
											error={
												touched.city ? errors.city : ''
											}
										/>
									)}
								</View>

								<View style={{ flex: 1 }}>
									{currentCountry === 'DZ' ? (
										<Dropdown
											isSearchable
											dropdownPosition="top"
											options={getCommunes(values.city)}
											labelProps={{
												label: 'Commune',
											}}
											onChangeValue={itemSelected => {
												setFieldValue(
													'zipCode',
													itemSelected.value
												);
												onChangeWilaya &&
													onChangeWilaya(values.city);
											}}
											values={{
												label: values.zipCode,
												value: values.zipCode ?? '',
											}}
											error={
												touched.zipCode ||
												touched.zipCode
													? errors.zipCode
													: ''
											}
										/>
									) : (
										<Input
											value={values.zipCode}
											containerStyles={{
												marginBottom:
													getResponsiveStyle(
														marginBottom
													),
											}}
											onChangeText={handleChange(
												'zipCode'
											)}
											onBlur={handleBlur('zipCode')}
											label={t('Zip Code')}
											error={
												touched.zipCode
													? errors.zipCode
													: ''
											}
										/>
									)}
								</View>
							</View>
						) : (
							<>
								<Input
									value={values.city}
									style={[styles.input]}
									containerStyles={{
										marginBottom: getResponsiveStyle(
											marginBottom + 10
										),
									}}
									onChangeText={handleChange('city')}
									onBlur={handleBlur('city')}
									label={t('City')}
									error={touched.city ? errors.city : ''}

									// placeholder="Enter account name"
								/>

								<Input
									value={values.zipCode}
									style={[styles.input]}
									containerStyles={{
										marginBottom: getResponsiveStyle(
											marginBottom + 10
										),
									}}
									onChangeText={handleChange('zipCode')}
									onBlur={handleBlur('zipCode')}
									label={t('Zip Code')}
									error={
										touched.zipCode ? errors.zipCode : ''
									}
									// placeholder="Enter account name"
								/>
							</>
						)}

						{/* <Label
							error={
								touched.country || touched.country
									? errors.country
									: ''
							}
							label="Country"
						/> */}
						<Dropdown
							isSearchable
							dropdownPosition="top"
							options={country}
							labelProps={{
								label: t('Country'),
							}}
							onChangeValue={itemSelected => {
								setFieldValue('countery', itemSelected.value);
								onChangeCountry &&
									onChangeCountry(itemSelected.value);
							}}
							values={{
								label: getCountryLabelFromValue(
									values.countery ?? '',
									country
								),
								value: values.countery ?? '',
							}}
							error={
								touched.countery || touched.countery
									? errors.countery
									: ''
							}
						/>
						<Input
							value={values.phoneNumber}
							style={[styles.input]}
							containerStyles={{
								marginTop: getResponsiveStyle(20),
							}}
							onChangeText={handleChange('phoneNumber')}
							onBlur={handleBlur('phoneNumber')}
							label={t('Phone Number')}
							error={
								touched.phoneNumber ? errors.phoneNumber : ''
							}
						/>
						{/* <Picker
							selectedValue={values.country}
							onValueChange={itemValue =>
								setFieldValue('country', itemValue)
							}
							// containerStyles={{
							// 	marginBottom: getResponsiveStyle(35),

							// 	flexBasis: '50%',
							// }}
							style={[
								styles.dropdownContainer,
								{
									marginBottom: getResponsiveStyle(35),
									flexBasis: '50%',
								},
								Platform.OS === 'web' &&
								!touched.country &&
								!errors.country
									? styleFocusOutline(primaryColors)
									: styleErrorOutline(),

								// styleInput(decoration),
								!!errors.country &&
									touched.country &&
									styles.inputError,
							]}
							dropdownIconColor={palettes.grey[1]}
							onBlur={handleBlur('country')}
							itemStyle={styles.dropdownItem}
						>
							<Picker.Item
								label="Country"
								value=""
								enabled={false}
							/>
							{countryNamesWithCodes.map(country => (
								<Picker.Item
									label={country.label}
									value={country.label}
								/>
							))}
						</Picker> */}
						{!isNotSaveForLater && isUserLoggedIn && (
							<Checkbox
								// hintText={getAgreementStatement()}
								containerStyles={{
									marginTop: getResponsiveStyle(
										14,
										'spacing'
									),
								}}
								hintText={`${t('Save info for later')}`}
								onChange={value =>
									setIsSaveAddress(!isSaveAddress)
								}
								value={isSaveAddress}
							/>
						)}
					</View>

					<Checkbox
						// hintText={getAgreementStatement()}
						containerStyles={{
							marginTop: getResponsiveStyle(14, 'spacing'),
						}}
						hintText={`${t(
							'Accept general conditions of use and sale'
						)}*`}
						isLink
						onPressLink={() =>
							Linking.openURL('https://www.ocpus.net/cgv/')
						}
						onChange={value =>
							setIsAcceptTermsAndCondition(
								!isAcceptTermsAndCondition
							)
						}
						value={isAcceptTermsAndCondition}
					/>

					<View
						style={[
							isLessThanDesktopBase
								? { flexDirection: 'column' }
								: styles.buttonContainer,
						]}
					>
						{/* {!isLessThanDesktopBase && (
							<Button
								title={t('Back')}
								variant="grey-outline"
								outerContainerProps={{
									style: styleLoginButtonOuterContainer(),
								}}
								width={getResponsiveStyle(40, 'spacing')}
								size="lg"
								loading={!isAddAddress && loading}
								onPress={onPressBack}
							/>
						)} */}

						{isLessThanDesktopBase && total && (
							<View
								style={{ display: 'flex', marginTop: '50px' }}
							>
								<View
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
									}}
								>
									<Text style={{ fontWeight: 'bold' }}>
										Sub-Total
									</Text>
									<Text>
										{getSymbolFromCurrency(
											(findMyCurrency?.currency ||
												findDefaultCountry?.currency) as string
										)}
										{(
											total *
											(findMyCountry?.exchangeRate || 1)
										)?.toFixed(2)}
									</Text>
								</View>

								<View
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
										marginBottom: '20px',
									}}
								>
									<Text style={{ fontWeight: 'bold' }}>
										Delivery Fee
									</Text>
									<Text>
										{getSymbolFromCurrency(
											(findMyCurrency?.currency ||
												findDefaultCountry?.currency) as string
										)}
										{Number(
											(deliveryFee || 0) *
												(+findMyCountry?.exchangeRate ||
													1)
										)?.toFixed(2)}
									</Text>
								</View>

								<View
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										flexDirection: 'row',
									}}
								>
									<Text style={{ fontWeight: 'bold' }}>
										{t('Amount to be Paid')}
									</Text>
									<Text>
										{getSymbolFromCurrency(
											(findMyCurrency?.currency ||
												findDefaultCountry?.currency) as string
										)}
										{(
											(amountToBePaid as number) *
												(findMyCountry?.exchangeRate ||
													1) ?? 0
										)?.toFixed(2)}
									</Text>
								</View>
							</View>
						)}

						{currentCountry === 'DZ' ||
						paymentMode === 'ON_DELIVERY' ? (
							<Button
								title={t('Pay on Delivery')}
								variant={
									isValid &&
									dirty &&
									isAcceptTermsAndCondition
										? 'primary'
										: 'disabled'
								}
								outerContainerProps={{
									style: [
										styleLoginButtonOuterContainer(),
										{
											width: isLessThanDesktopBase
												? '100%'
												: '35%',
										},
									] as any,
								}}
								// width={getResponsiveStyle(
								// 	isLessThanDesktopBase ? 155 : 70,
								// 	'spacing'
								// )}
								size="lg"
								loading={createOrderLoading}
								onPress={() => {
									payOnDelivery && payOnDelivery(values);
									dispatch(
										setPayingAddressDetails({
											address: values.address,
											city: values.city,
											countery: values.countery,
											name: `${values?.firstName} ${values?.lastName}`,
											zipCode: values.zipCode,
											phoneNumber: values.phoneNumber,
											email: values?.email,
											firstName: values?.firstName,
											lastName: values?.lastName,
										})
									);
								}}
							/>
						) : (
							<Button
								title={
									isAddAddress
										? t('Save')
										: t('Proceed to Checkout')
								}
								// variant="disabled"
								variant={
									isValid &&
									!!values.countery &&
									isAcceptTermsAndCondition
										? 'primary'
										: 'disabled'
								}
								outerContainerProps={{
									style: [
										styleLoginButtonOuterContainer(),
										{
											width: isLessThanDesktopBase
												? '100%'
												: '35%',
										},
									],
								}}
								// width={getResponsiveStyle(
								// 	isLessThanDesktopBase ? 155 : 70,
								// 	'spacing'
								// )}
								size="lg"
								loading={loading}
								onPress={handleSubmit}
							/>
						)}
					</View>
				</>
			)}
		</Formik>
	);
};

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	countryContainer: {
		flexBasis: '100%',
		height: '50px',
	},
	dropdownContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		// box shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: getResponsiveStyle(48, 'dimensions'),
		paddingRight: getResponsiveStyle(24),
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		marginBottom: getResponsiveStyle(35),
		paddingLeft: getResponsiveStyle(19),
	},
	dropdownItem: {
		paddingVertical: 20,
	},
	inputError: {
		borderColor: palettes.red[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
});

export default AddAddressForm;

function styleLoginButtonOuterContainer() {
	return {
		marginTop: getResponsiveStyle(18),
	};
}

function styleErrorOutline() {
	return {
		outlineColor: palettes.red[0],
		outerWidth: 1,
	} as any;
}

function styleFocusOutline(primary: string[]) {
	return {
		outlineColor: primary[0],
		outerWidth: 1,
	} as any;
}
