import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Image,
	Platform,
	ScrollView,
	StyleSheet,
	Text,
	View,
} from 'react-native';
import { CREATE_CART } from '../../../graphql/cart/mutation';
import { GET_CART } from '../../../graphql/cart/queries';
import { GET_PRODUCT } from '../../../graphql/product/queries';
import { usePrimaryColors } from '../../../hooks/usePrimaryColors';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { placeholderImage } from '../../../utils/productCategories';
import {
	isBuyingOngoing,
	isEevntOngoing,
} from '../../../utils/statusOfEvent/isEventOngoing';
import ProductDescription from '../../live-event/product-section/event-product/product-detail/ProductDescription';
import ProductPriceInfo from '../../live-event/product-section/event-product/product-detail/ProductPriceInfo';
import Button from '../button/Button';
import { isEventDone } from '../../../utils/statusOfEvent/isEventDone';
import { setTypeOfAccount } from '../../../redux/createUserSlice';
import {
	setPrimaryColors,
	setSignupSidebarValues,
} from '../../../redux/uiSlice';
import { useNavigation } from '@react-navigation/native';
import { TUseNavigation } from '../../../types/exportedTypes';
import { setGlobalCartOpen, setVisitorCart } from '../../../redux/userSlice';

type Props = {
	variant: PopulatedVariant;
	productData?: TGetAllProductsData;
	eventDetails?: TODO;
	setModalState?: (val: boolean) => void;
};
const ProductView = ({
	variant,
	productData,
	eventDetails,
	setModalState,
}: Props) => {
	const { t } = useTranslation();
	const primary = usePrimaryColors();
	const { setAlert } = useSetAlert();
	const [product, setProduct] = useState<TGetAllProductsData>();
	const [optionValue, setOptionValue] = useState<string>(
		variant.option.values[0]?.name
	);
	const [isLoadingVisitorAddToCart, setIsLoadingVisitorAddToCart] =
		useState(false);
	const [selectedVariant, setSelectedVariant] =
		useState<PopulatedVariant>(variant);
	const { loggedInUserDetails, isUserLoggedIn } = useAppSelector(
		state => state.auth
	);
	const dispatch = useAppDispatch();
	const navigation = useNavigation<TUseNavigation>();
	const [getProduct, { loading }] = useLazyQuery(GET_PRODUCT, {
		variables: {
			_id: variant?.associatedProduct?._id,
		},

		onCompleted: data => {
			const product = data?.getProduct?.data;
			setProduct(data?.getProduct?.data);
			setSelectedVariant({
				_id: variant._id,
				variator: variant?.variator,
				value: variant?.value,
				vat: variant?.vat,
				requiresShipping: true,
				image: variant?.image,
				option: variant?.option,
				associatedProduct: {
					_id: product?._id,
					name: product?.name,
					shortDescription: product?.shortDescription,
					description: product?.description,
					category: product?.category,
					publishedStatus: product?.publishedStatus,
					images: product?.images,
					brand: product?.brand,
				},
			});
		},
	});

	const onChangeVariant = (variant: Variant) => {
		product &&
			setSelectedVariant({
				_id: variant._id,
				variator: variant?.variator,
				value: variant?.value,
				vat: variant?.vat,
				requiresShipping: true,
				image: variant?.image,
				option: variant?.option,
				associatedProduct: {
					_id: product._id,
					name: product.name,
					shortDescription: product.shortDescription,
					description: product.description,
					category: product.category,
					publishedStatus: product?.publishedStatus,
					images: product.images,
					brand: product.brand,
				},
			});
	};
	const showLocks = useAppSelector(state => state.getEvent.showLocks);
	const { visitorCartStore } = useAppSelector(state => state.user);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];
	const productBrand =
		eventDetails?.eventDetails?.eventDetails?.affiliatedBrands?.[0]?._id ||
		eventDetails?.affiliatedBrands?.[0]?._id ||
		productData?.brand?._id;
	const brandInCartArray = usersCart.map(
		item =>
			item?.productId?.associatedProduct?.brand?._id ??
			item?.starterkitId?.brand?._id
	);
	const brandInCart = new Set(brandInCartArray);

	// console.log({ brandInCart, brandInCartArray, productBrand });

	let inCart = false;
	usersCart.map(cartVariant => {
		if (
			cartVariant.type === 'Product' &&
			cartVariant?.productId?._id === variant?._id
		) {
			if (!cartVariant?.productId?.option?.values?.length) {
				inCart = true;
			} else if (cartVariant?.optionValue === optionValue) {
				inCart = true;
			}
		}
	});
	const [createCart, { data: createData, loading: createLoading }] =
		useMutation(CREATE_CART);

	const availableVariantCombination: any = () => {
		const variantWithQuantity = Object.values(
			variant?.option?.variatorValues?.[0]?.variatorValues || {}
		)?.find((data: any) => data?.quantity >= 1);
		return variantWithQuantity;
	};

	const isItemOutOfStock = availableVariantCombination()
		? availableVariantCombination()?.quantity <= 0
		: +variant?.option?.inventory?.quantity <= 0;
	// console.log({ varaintCombinations: availableVariantCombination() }, variant?.option?.inventory?.quantity)

	const addToCartText = inCart
		? 'In Cart'
		: isItemOutOfStock
		? t('Out of stock')
		: t('Add to cart');

	const buttonVariant = variant?.option?.values?.length
		? optionValue
			? 'primary'
			: 'disabled'
		: 'primary';

	const buttonVariantWithStockQuantity = isItemOutOfStock
		? 'disabled'
		: buttonVariant;

	const mutateCart = () => {
		if (!isUserLoggedIn) {
			if (brandInCart.size === 0 || brandInCart.has(productBrand)) {
				setIsLoadingVisitorAddToCart(true);
				const cartItem = {
					eventId: event,
					quantity: 1,
					productId: selectedVariant,
					type: 'Product',
					optionValue,
					price: Number(
						selectedVariant?.option?.values?.find(
							value => value.name === optionValue
						)?.price
					),
				};

				console.log({ cartItem, productData });

				setTimeout(() => {
					if (visitorCartStore?.length) {
						const addToCart = [...visitorCartStore, cartItem];
						dispatch(setVisitorCart(addToCart as Cart));
					} else {
						dispatch(setVisitorCart([cartItem] as any));
					}
					setAlert(t('Item Added to Cart'), 'normal');
					setModalState && setModalState(false);
					setIsLoadingVisitorAddToCart(false);
					dispatch(setGlobalCartOpen(true));
				}, 500);
			} else {
				setModalState && setModalState(false);
				setAlert(
					t('You cannot add items of different brands to cart'),
					'danger'
				);
			}

			// dispatch(setTypeOfAccount('CONSUMER'));
			// dispatch(setPrimaryColors('CONSUMER'));
			// dispatch(setSignupSidebarValues('CONSUMER'));
			// navigation.navigate('PersonalInfo');
			return;
		}
		if (isItemOutOfStock) {
			setAlert(t('Out of stock'), 'danger');
			return;
		}
		// console.log({
		// 	hadBrand: brandInCart.has(productBrand),
		// 	productBrand,
		// 	brandInCart,
		// 	eventDetails,
		// });

		if (brandInCart.size === 0 || brandInCart.has(productBrand)) {
			createCart({
				variables: {
					args: {
						items: {
							eventId: event?._id,
							quantity: 1,
							productId: selectedVariant?._id,
							type: 'Product',
							optionValue,
							price: Number(
								selectedVariant?.option?.values?.find(
									value => value.name === optionValue
								)?.price
							),
						},
					},
				},
				awaitRefetchQueries: true,
				refetchQueries: [{ query: GET_CART }],
				onCompleted: data => {
					setModalState && setModalState(false);
					dispatch(setGlobalCartOpen(true));
					if (data.createCart.success) {
						setAlert(t('Item Added to Cart'), 'normal');
					} else {
						setAlert(t('Unable to add item to cart'), 'danger');
					}
				},
				onError: () => {
					setAlert(t('Unable to add item to cart'), 'danger');
				},
			});
		} else {
			setModalState && setModalState(false);
			setAlert(
				t('You cannot add items of different brands to cart'),
				'danger'
			);
		}
	};
	useEffect(() => {
		productData
			? setProduct(productData)
			: getProduct({
					variables: {
						_id:
							variant?.associatedProduct._id ??
							variant?.associatedProduct,
					},
			  });
	}, [variant, productData]);
	const event = eventDetails?.eventDetails?.eventDetails ?? eventDetails;
	const { typeOfAccount, _id: userId } =
		useAppSelector(state => state.auth.loggedInUserDetails) || {};
	useEffect(() => {
		setOptionValue(selectedVariant.option.values[0]?.name);
	}, [selectedVariant]);

	const isShowAddToCart = () => {
		return (
			!inCart &&
			(typeOfAccount === 'CONSUMER' || !typeOfAccount) &&
			(event?.invitedPeople?.find(
				(user: TGetAllUsersData) => user?._id === userId
			) ||
				!showLocks ||
				!typeOfAccount) &&
			event?._id &&
			(isEevntOngoing(event) ||
				isBuyingOngoing(event) ||
				!isEventDone(event))
		);
	};

	return (
		<>
			<View style={{ height: '80vh' }}>
				<ScrollView
					style={styles.productScrollContainer}
					showsHorizontalScrollIndicator={false}
				>
					<Image
						style={styles.image}
						source={{
							uri:
								selectedVariant?.image?.src ?? placeholderImage,
						}}
					/>
					<ProductPriceInfo
						variant={selectedVariant}
						optionValue={optionValue}
						availableVariantCombinationPrice={availableVariantCombination()}
						brand={event?.affiliatedBrands?.[0]}
						highestPrice={
							product &&
							Math.max(
								...product?.variants.map(variant => {
									return Math.max(
										...variant?.option?.values?.map(
											value => value?.price
										)
									);
								})
							)
						}
					/>

					<View
						style={[
							{
								width: '100%',
								backgroundColor: '#fff',
								paddingBottom: 0,
								marginBottom: getResponsiveStyle(10),
							},
							// Platform.select({
							// 	native: {
							// 		bottom: 0,
							// 		left: 0,
							// 	},
							// 	default: {
							// 		bottom: 0,
							// 		left: 0,
							// 	},
							// }),
						]}
					>
						{inCart ? (
							<View
								style={[
									styles.downloadBtn,
									stylePrimaryBorderColor(primary[0]),
								]}
							>
								<Text
									style={[
										styles.downloadText,
										stylePrimaryColor(primary[0]),
									]}
								>
									{addToCartText}
								</Text>
							</View>
						) : !inCart &&
						  (createLoading || isLoadingVisitorAddToCart) ? (
							<View
								style={[
									styles.downloadBtn,
									stylePrimaryBorderColor(primary[0]),
								]}
							>
								<Text
									style={[
										styles.downloadText,
										stylePrimaryColor(primary[0]),
									]}
								>
									{t('Adding to Cart...')}
								</Text>
							</View>
						) : (
							<>
								{isShowAddToCart() && (
									<View>
										<Button
											title={addToCartText}
											variant={
												buttonVariantWithStockQuantity
											}
											onPress={() =>
												variant && mutateCart()
											}
										/>
									</View>
								)}
								{!inCart &&
									typeOfAccount === 'HOST' &&
									!event?._id && (
										<View>
											<Button
												title={addToCartText}
												variant={
													buttonVariantWithStockQuantity
												}
												onPress={() =>
													variant && mutateCart()
												}
											/>
										</View>
									)}
							</>
						)}
					</View>

					<ProductDescription
						event={
							eventDetails?.eventDetails?.eventDetails ??
							eventDetails
						}
						optionValue={optionValue}
						setOptionValue={setOptionValue}
						variant={selectedVariant}
						allVariants={product?.variants.filter(
							variantVal => variantVal._id != selectedVariant._id
						)}
						loading={loading}
						onChangeVariant={onChangeVariant}
						setModalState={setModalState}
						productData={productData}
						// {...product}
					/>
				</ScrollView>
			</View>
		</>
	);
};
export default ProductView;
const styles = StyleSheet.create({
	image: {
		width: '100%',
		height: '36vh',
		borderRadius: 4,
	},
	productScrollContainer: {
		height: '100%',
		width: '100%',
		overflow: 'scroll',
	},
	downloadBtn: {
		height: 40,
		justifyContent: 'center',
		alignItems: 'center',
		// border: '1px solid #6645F4',
		borderWidth: 1,
		borderRadius: 6,
		// position: 'absolute',
		// bottom: 10,
		// width: '90%',
	},
	downloadText: {
		fontSize: 12,
		fontStyle: 'normal',
		fontWeight: '600',
		// color: '#6645F4',
	},
});
function stylePrimaryBorderColor(color: string) {
	return {
		borderColor: color,
	};
}

function stylePrimaryColor(color: string) {
	return {
		color: color,
	};
}
