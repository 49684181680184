import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setDefaultCard } from '../../redux/getCardsSlice';
import { useLazyQuery } from '@apollo/client';
import { GET_APP_CONFIG } from '../../graphql/config/queries';
import countryNamesWithCodes, {
	getWilayaGroupFee,
} from '../../utils/countryNamesWithCodes';
import { useGetSingleBrandLazy } from '../../api/brands/useGetSingleBrand';

export const useCheckoutCommon = ({
	brandInCartIds,
	isCheckoutScreen,
}: any) => {
	const dispatch = useAppDispatch();

	const payingAddressDeets = useAppSelector(
		state => state.oneStopCheckout.address
	);
	const { country } = useAppSelector(state => state.ui);

	const { visitorCartStore } = useAppSelector(state => state.user);
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];

	// states
	const [deliveryFee, setDeliveryFee] = useState(0);
	const [maxOrderForDeliveryFee, setMaxOrderForDeliveryFree] = useState(0);
	const [amountToBePaid, setAmountToBePaid] = useState<number>(0);
	const [paymentMode, setPaymentMode] = useState('');
	const [countryParam, setCountryParam] = useState<TBrandAllowedCountries[]>(
		[]
	);

	let Total = 0;
	usersCart.map(product => {
		Total +=
			product.type === 'Product'
				? product?.price * product.quantity
				: product.starterkitId.price * product.quantity;
	});

	const [getAppConfig_] = useLazyQuery(GET_APP_CONFIG);

	const { getSingleBrand } = useGetSingleBrandLazy({
		onCompleted: data => {
			const brandAllowedCountriesData =
				data?.getBrand?.data?.brandAllowedCountries;
			const getCountries = brandAllowedCountriesData?.map(item => {
				const findCountry = countryNamesWithCodes?.find(
					data => data?.value == item?.countryCode
				);
				return {
					country: findCountry,
					...item,
				};
			});

			setCountryParam((getCountries || []) as any);
		},
	});

	useEffect(() => {
		getSingleBrand(brandInCartIds?.[0]);
	}, []);

	// useEffect(() => {
	//     getAppConfig_({
	//         variables: {
	//             key: 'MAXIMUM_ELIGIBLE_FREE_SHIPPING_AMOUNT',
	//         },
	//         onCompleted: data => {
	//             setDeliveryFee(
	//                 Total < +data?.getAppConfig?.data?.value?.value2
	//                     ? +data?.getAppConfig?.data?.value?.value1
	//                     : 0
	//             );
	//             setMaxOrderForDeliveryFree(
	//                 data?.getAppConfig?.data?.value?.value2
	//             );
	//         },
	//     });
	// }, []);

	useEffect(() => {
		if (Total < +maxOrderForDeliveryFee) {
			setAmountToBePaid(Total + deliveryFee);
		} else {
			setAmountToBePaid(Total);
		}
	}, [deliveryFee, maxOrderForDeliveryFee]);

	// useEffect(() => {
	// 	setAmountToBePaid(Total + deliveryFee);
	// }, [deliveryFee]);

	useEffect(() => {
		if (payingAddressDeets.countery && isCheckoutScreen) {
			onChangeCountry(payingAddressDeets.countery);
		} else if (country) {
			onChangeCountry(country?.toLocaleLowerCase());
		}
	}, [payingAddressDeets.countery, countryParam, country]);

	const cards = useAppSelector(state => state.userCards.UserCards);
	useEffect(() => {
		for (let i = 0; i < cards.length; i++) {
			if (cards[i].default) {
				dispatch(setDefaultCard(cards[i]));
				break;
			}
		}
	}, [cards]);

	const onChangeWilaya = (wilaya: string) => {
		setDeliveryFee(getWilayaGroupFee(wilaya, Total * 10) as number);
	};

	const onChangeCountry = (countryCode: string) => {
		const country = countryParam?.find(
			(data: any) => data?.countryCode == countryCode
		);
		if (country?.shippingFee && country?.freeShippingThreshold) {
			if (Total < +country.freeShippingThreshold)
				setDeliveryFee(+country.shippingFee);
			setMaxOrderForDeliveryFree(+country.freeShippingThreshold);
			setPaymentMode(country.paymentOption);
		}
	};

	return {
		amountToBePaid,
		deliveryFee,
		maxOrderForDeliveryFee,
		paymentMode,
		Total,
		onChangeCountry,
		setAmountToBePaid,
		onChangeWilaya,
	};
};
