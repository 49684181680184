export default [
	{ value: 'af', label: 'Afghanistan', currency: 'AFN' },
	{ value: 'ax', label: 'Åland Islands', currency: 'EUR' },
	{ value: 'al', label: 'Albania', currency: 'ALL' },
	{ value: 'dz', label: 'Algeria', currency: 'DZD' },
	{ value: 'as', label: 'American Samoa', currency: 'USD' },
	{ value: 'ad', label: 'Andorra', currency: 'EUR' },
	{ value: 'ao', label: 'Angola', currency: 'AOA' },
	{ value: 'ai', label: 'Anguilla', currency: 'XCD' },
	{ value: 'aq', label: 'Antarctica', currency: 'XXX' },
	{ value: 'ag', label: 'Antigua and Barbuda', currency: 'XCD' },
	{ value: 'ar', label: 'Argentina', currency: 'ARS' },
	{ value: 'am', label: 'Armenia', currency: 'AMD' },
	{ value: 'aw', label: 'Aruba', currency: 'AWG' },
	{ value: 'au', label: 'Australia', currency: 'AUD' },
	{ value: 'at', label: 'Austria', currency: 'EUR' },
	{ value: 'az', label: 'Azerbaijan', currency: 'AZN' },
	{ value: 'bs', label: 'Bahamas', currency: 'BSD' },
	{ value: 'bh', label: 'Bahrain', currency: 'BHD' },
	{ value: 'bd', label: 'Bangladesh', currency: 'BDT' },
	{ value: 'bb', label: 'Barbados', currency: 'BBD' },
	{ value: 'by', label: 'Belarus', currency: 'BYN' },
	{ value: 'be', label: 'Belgium', currency: 'EUR' },
	{ value: 'bz', label: 'Belize', currency: 'BZD' },
	{ value: 'bj', label: 'Benin', currency: 'XOF' },
	{ value: 'bm', label: 'Bermuda', currency: 'BMD' },
	{ value: 'bt', label: 'Bhutan', currency: 'BTN' },
	{ value: 'bo', label: 'Bolivia', currency: 'BOB' },
	{ value: 'ba', label: 'Bosnia and Herzegovina', currency: 'BAM' },
	{ value: 'bw', label: 'Botswana', currency: 'BWP' },
	{ value: 'bv', label: 'Bouvet Island', currency: 'NOK' },
	{ value: 'br', label: 'Brazil', currency: 'BRL' },
	{ value: 'io', label: 'British Indian Ocean Territory', currency: 'USD' },
	{ value: 'vg', label: 'British Virgin Islands', currency: 'USD' },
	{ value: 'bn', label: 'Brunei', currency: 'BND' },
	{ value: 'bg', label: 'Bulgaria', currency: 'BGN' },
	{ value: 'bf', label: 'Burkina Faso', currency: 'XOF' },
	{ value: 'bi', label: 'Burundi', currency: 'BIF' },
	{ value: 'kh', label: 'Cambodia', currency: 'KHR' },
	{ value: 'cm', label: 'Cameroon', currency: 'XAF' },
	{ value: 'ca', label: 'Canada', currency: 'CAD' },
	{ value: 'cv', label: 'Cape Verde', currency: 'CVE' },
	{ value: 'bq', label: 'Caribbean Netherlands', currency: 'USD' },
	{ value: 'ky', label: 'Cayman Islands', currency: 'KYD' },
	{ value: 'cf', label: 'Central African Republic', currency: 'XAF' },
	{ value: 'td', label: 'Chad', currency: 'XAF' },
	{ value: 'cl', label: 'Chile', currency: 'CLP' },
	{ value: 'cn', label: 'China', currency: 'CNY' },
	{ value: 'cx', label: 'Christmas Island', currency: 'AUD' },
	{ value: 'cc', label: 'Cocos (Keeling) Islands', currency: 'AUD' },
	{ value: 'co', label: 'Colombia', currency: 'COP' },
	{ value: 'km', label: 'Comoros', currency: 'KMF' },
	{ value: 'ck', label: 'Cook Islands', currency: 'NZD' },
	{ value: 'cr', label: 'Costa Rica', currency: 'CRC' },
	{ value: 'ci', label: "Côte d'Ivoire (Ivory Coast)", currency: 'XOF' },
	{ value: 'hr', label: 'Croatia', currency: 'EUR' },
	{ value: 'cu', label: 'Cuba', currency: 'CUP' },
	{ value: 'cw', label: 'Curaçao', currency: 'ANG' },
	{ value: 'cy', label: 'Cyprus', currency: 'EUR' },
	{ value: 'cz', label: 'Czechia', currency: 'CZK' },
	{ value: 'dk', label: 'Denmark', currency: 'DKK' },
	{ value: 'dj', label: 'Djibouti', currency: 'DJF' },
	{ value: 'dm', label: 'Dominica', currency: 'XCD' },
	{ value: 'do', label: 'Dominican Republic', currency: 'DOP' },
	{ value: 'cd', label: 'DR Congo', currency: 'CDF' },
	{ value: 'ec', label: 'Ecuador', currency: 'USD' },
	{ value: 'eg', label: 'Egypt', currency: 'EGP' },
	{ value: 'sv', label: 'El Salvador', currency: 'USD' },
	{ value: 'gq', label: 'Equatorial Guinea', currency: 'XAF' },
	{ value: 'er', label: 'Eritrea', currency: 'ERN' },
	{ value: 'ee', label: 'Estonia', currency: 'EUR' },
	{ value: 'sz', label: 'Eswatini (Swaziland)', currency: 'SZL' },
	{ value: 'et', label: 'Ethiopia', currency: 'ETB' },
	{ value: 'eu', label: 'European Union', currency: 'EUR' },
	{ value: 'fk', label: 'Falkland Islands', currency: 'FKP' },
	{ value: 'fo', label: 'Faroe Islands', currency: 'DKK' },
	{ value: 'fj', label: 'Fiji', currency: 'FJD' },
	{ value: 'fi', label: 'Finland', currency: 'EUR' },
	{ value: 'fr', label: 'France', currency: 'EUR' },
	{ value: 'gf', label: 'French Guiana', currency: 'EUR' },
	{ value: 'pf', label: 'French Polynesia', currency: 'XPF' },
	{
		value: 'tf',
		label: 'French Southern and Antarctic Lands',
		currency: 'EUR',
	},
	{ value: 'ga', label: 'Gabon', currency: 'XAF' },
	{ value: 'gm', label: 'Gambia', currency: 'GMD' },
	{ value: 'ge', label: 'Georgia', currency: 'GEL' },
	{ value: 'de', label: 'Germany', currency: 'EUR' },
	{ value: 'gh', label: 'Ghana', currency: 'GHS' },
	{ value: 'gi', label: 'Gibraltar', currency: 'GIP' },
	{ value: 'gr', label: 'Greece', currency: 'EUR' },
	{ value: 'gl', label: 'Greenland', currency: 'DKK' },
	{ value: 'gd', label: 'Grenada', currency: 'XCD' },
	{ value: 'gp', label: 'Guadeloupe', currency: 'EUR' },
	{ value: 'gu', label: 'Guam', currency: 'USD' },
	{ value: 'gt', label: 'Guatemala', currency: 'GTQ' },
	{ value: 'gg', label: 'Guernsey', currency: 'GBP' },
	{ value: 'gn', label: 'Guinea', currency: 'GNF' },
	{ value: 'gw', label: 'Guinea-Bissau', currency: 'XOF' },
	{ value: 'gy', label: 'Guyana', currency: 'GYD' },
	{ value: 'ht', label: 'Haiti', currency: 'HTG' },
	{
		value: 'hm',
		label: 'Heard Island and McDonald Islands',
		currency: 'AUD',
	},
	{ value: 'hn', label: 'Honduras', currency: 'HNL' },
	{ value: 'hk', label: 'Hong Kong', currency: 'HKD' },
	{ value: 'hu', label: 'Hungary', currency: 'HUF' },
	{ value: 'is', label: 'Iceland', currency: 'ISK' },
	{ value: 'in', label: 'India', currency: 'INR' },
	{ value: 'id', label: 'Indonesia', currency: 'IDR' },
	{ value: 'ir', label: 'Iran', currency: 'IRR' },
	{ value: 'iq', label: 'Iraq', currency: 'IQD' },
	{ value: 'ie', label: 'Ireland', currency: 'EUR' },
	{ value: 'im', label: 'Isle of Man', currency: 'GBP' },
	{ value: 'il', label: 'Israel', currency: 'ILS' },
	{ value: 'it', label: 'Italy', currency: 'EUR' },
	{ value: 'jm', label: 'Jamaica', currency: 'JMD' },
	{ value: 'jp', label: 'Japan', currency: 'JPY' },
	{ value: 'je', label: 'Jersey', currency: 'GBP' },
	{ value: 'jo', label: 'Jordan', currency: 'JOD' },
	{ value: 'kz', label: 'Kazakhstan', currency: 'KZT' },
	{ value: 'ke', label: 'Kenya', currency: 'KES' },
	{ value: 'ki', label: 'Kiribati', currency: 'AUD' },
	{ value: 'xk', label: 'Kosovo', currency: 'EUR' },
	{ value: 'kw', label: 'Kuwait', currency: 'KWD' },
	{ value: 'kg', label: 'Kyrgyzstan', currency: 'KGS' },
	{ value: 'la', label: 'Laos', currency: 'LAK' },
	{ value: 'lv', label: 'Latvia', currency: 'EUR' },
	{ value: 'lb', label: 'Lebanon', currency: 'LBP' },
	{ value: 'ls', label: 'Lesotho', currency: 'LSL' },
	{ value: 'lr', label: 'Liberia', currency: 'LRD' },
	{ value: 'ly', label: 'Libya', currency: 'LYD' },
	{ value: 'li', label: 'Liechtenstein', currency: 'CHF' },
	{ value: 'lt', label: 'Lithuania', currency: 'EUR' },
	{ value: 'lu', label: 'Luxembourg', currency: 'EUR' },
	{ value: 'mo', label: 'Macau', currency: 'MOP' },
	{ value: 'mg', label: 'Madagascar', currency: 'MGA' },
	{ value: 'mw', label: 'Malawi', currency: 'MWK' },
	{ value: 'my', label: 'Malaysia', currency: 'MYR' },
	{ value: 'mv', label: 'Maldives', currency: 'MVR' },
	{ value: 'ml', label: 'Mali', currency: 'XOF' },
	{ value: 'mt', label: 'Malta', currency: 'EUR' },
	{ value: 'mh', label: 'Marshall Islands', currency: 'USD' },
	{ value: 'mq', label: 'Martinique', currency: 'EUR' },
	{ value: 'mr', label: 'Mauritania', currency: 'MRU' },
	{ value: 'mu', label: 'Mauritius', currency: 'MUR' },
	{ value: 'yt', label: 'Mayotte', currency: 'EUR' },
	{ value: 'mx', label: 'Mexico', currency: 'MXN' },
	{ value: 'fm', label: 'Micronesia', currency: 'USD' },
	{ value: 'md', label: 'Moldova', currency: 'MDL' },
	{ value: 'mc', label: 'Monaco', currency: 'EUR' },
	{ value: 'mn', label: 'Mongolia', currency: 'MNT' },
	{ value: 'me', label: 'Montenegro', currency: 'EUR' },
	{ value: 'ms', label: 'Montserrat', currency: 'XCD' },
	{ value: 'ma', label: 'Morocco', currency: 'MAD' },
	{ value: 'mz', label: 'Mozambique', currency: 'MZN' },
	{ value: 'mm', label: 'Myanmar', currency: 'MMK' },
	{ value: 'na', label: 'Namibia', currency: 'NAD' },
	{ value: 'nr', label: 'Nauru', currency: 'AUD' },
	{ value: 'np', label: 'Nepal', currency: 'NPR' },
	{ value: 'nl', label: 'Netherlands', currency: 'EUR' },
	{ value: 'nc', label: 'New Caledonia', currency: 'XPF' },
	{ value: 'nz', label: 'New Zealand', currency: 'NZD' },
	{ value: 'ni', label: 'Nicaragua', currency: 'NIO' },
	{ value: 'ne', label: 'Niger', currency: 'XOF' },
	{ value: 'ng', label: 'Nigeria', currency: 'NGN' },
	{ value: 'nu', label: 'Niue', currency: 'NZD' },
	{ value: 'nf', label: 'Norfolk Island', currency: 'AUD' },
	{ value: 'kp', label: 'North Korea', currency: 'KPW' },
	{ value: 'mk', label: 'North Macedonia', currency: 'MKD' },
	{ value: 'mp', label: 'Northern Mariana Islands', currency: 'USD' },
	{ value: 'no', label: 'Norway', currency: 'NOK' },
	{ value: 'om', label: 'Oman', currency: 'OMR' },
	{ value: 'pk', label: 'Pakistan', currency: 'PKR' },
	{ value: 'pw', label: 'Palau', currency: 'USD' },
	{ value: 'ps', label: 'Palestine', currency: 'ILS' },
	{ value: 'pa', label: 'Panama', currency: 'PAB' },
	{ value: 'pg', label: 'Papua New Guinea', currency: 'PGK' },
	{ value: 'py', label: 'Paraguay', currency: 'PYG' },
	{ value: 'pe', label: 'Peru', currency: 'PEN' },
	{ value: 'ph', label: 'Philippines', currency: 'PHP' },
	{ value: 'pn', label: 'Pitcairn Islands', currency: 'NZD' },
	{ value: 'pl', label: 'Poland', currency: 'PLN' },
	{ value: 'pt', label: 'Portugal', currency: 'EUR' },
	{ value: 'pr', label: 'Puerto Rico', currency: 'USD' },
	{ value: 'qa', label: 'Qatar', currency: 'QAR' },
	{ value: 'cg', label: 'Republic of the Congo', currency: 'XAF' },
	{ value: 're', label: 'Réunion', currency: 'EUR' },
	{ value: 'ro', label: 'Romania', currency: 'RON' },
	{ value: 'ru', label: 'Russia', currency: 'RUB' },
	{ value: 'rw', label: 'Rwanda', currency: 'RWF' },
	{ value: 'bl', label: 'Saint Barthélemy', currency: 'EUR' },
	{
		value: 'sh',
		label: 'Saint Helena, Ascension and Tristan da Cunha',
		currency: 'SHP',
	},
	{ value: 'kn', label: 'Saint Kitts and Nevis', currency: 'XCD' },
	{ value: 'lc', label: 'Saint Lucia', currency: 'XCD' },
	{ value: 'mf', label: 'Saint Martin', currency: 'EUR' },
	{ value: 'pm', label: 'Saint Pierre and Miquelon', currency: 'EUR' },
	{ value: 'vc', label: 'Saint Vincent and the Grenadines', currency: 'XCD' },
	{ value: 'ws', label: 'Samoa', currency: 'WST' },
	{ value: 'sm', label: 'San Marino', currency: 'EUR' },
	{ value: 'st', label: 'São Tomé and Príncipe', currency: 'STN' },
	{ value: 'sa', label: 'Saudi Arabia', currency: 'SAR' },
	{ value: 'sn', label: 'Senegal', currency: 'XOF' },
	{ value: 'rs', label: 'Serbia', currency: 'RSD' },
	{ value: 'sc', label: 'Seychelles', currency: 'SCR' },
	{ value: 'sl', label: 'Sierra Leone', currency: 'SLL' },
	{ value: 'sg', label: 'Singapore', currency: 'SGD' },
	{ value: 'sx', label: 'Sint Maarten', currency: 'ANG' },
	{ value: 'sk', label: 'Slovakia', currency: 'EUR' },
	{ value: 'si', label: 'Slovenia', currency: 'EUR' },
	{ value: 'sb', label: 'Solomon Islands', currency: 'SBD' },
	{ value: 'so', label: 'Somalia', currency: 'SOS' },
	{ value: 'za', label: 'South Africa', currency: 'ZAR' },
	{ value: 'gs', label: 'South Georgia', currency: 'GBP' },
	{ value: 'kr', label: 'South Korea', currency: 'KRW' },
	{ value: 'ss', label: 'South Sudan', currency: 'SSP' },
	{ value: 'es', label: 'Spain', currency: 'EUR' },
	{ value: 'lk', label: 'Sri Lanka', currency: 'LKR' },
	{ value: 'sd', label: 'Sudan', currency: 'SDG' },
	{ value: 'sr', label: 'Suriname', currency: 'SRD' },
	{ value: 'sj', label: 'Svalbard and Jan Mayen', currency: 'NOK' },
	{ value: 'se', label: 'Sweden', currency: 'SEK' },
	{ value: 'ch', label: 'Switzerland', currency: 'CHF' },
	{ value: 'sy', label: 'Syria', currency: 'SYP' },
	{ value: 'tw', label: 'Taiwan', currency: 'TWD' },
	{ value: 'tj', label: 'Tajikistan', currency: 'TJS' },
	{ value: 'tz', label: 'Tanzania', currency: 'TZS' },
	{ value: 'th', label: 'Thailand', currency: 'THB' },
	{ value: 'tl', label: 'Timor-Leste', currency: 'USD' },
	{ value: 'tg', label: 'Togo', currency: 'XOF' },
	{ value: 'tk', label: 'Tokelau', currency: 'NZD' },
	{ value: 'to', label: 'Tonga', currency: 'TOP' },
	{ value: 'tt', label: 'Trinidad and Tobago', currency: 'TTD' },
	{ value: 'tn', label: 'Tunisia', currency: 'TND' },
	{ value: 'tr', label: 'Turkey', currency: 'TRY' },
	{ value: 'tm', label: 'Turkmenistan', currency: 'TMT' },
	{ value: 'tc', label: 'Turks and Caicos Islands', currency: 'USD' },
	{ value: 'tv', label: 'Tuvalu', currency: 'AUD' },
	{ value: 'ug', label: 'Uganda', currency: 'UGX' },
	{ value: 'ua', label: 'Ukraine', currency: 'UAH' },
	{ value: 'ae', label: 'United Arab Emirates', currency: 'AED' },
	{ value: 'gb', label: 'United Kingdom', currency: 'GBP' },
	{ value: 'un', label: 'United Nations', currency: 'USD' },
	{ value: 'us', label: 'United States', currency: 'USD' },
	{
		value: 'um',
		label: 'United States Minor Outlying Islands',
		currency: 'USD',
	},
	{ value: 'vi', label: 'United States Virgin Islands', currency: 'USD' },
	{ value: 'uy', label: 'Uruguay', currency: 'UYU' },
	{ value: 'uz', label: 'Uzbekistan', currency: 'UZS' },
	{ value: 'vu', label: 'Vanuatu', currency: 'VUV' },
	{ value: 'va', label: 'Vatican City (Holy See)', currency: 'EUR' },
	{ value: 've', label: 'Venezuela', currency: 'VES' },
	{ value: 'vn', label: 'Vietnam', currency: 'VND' },
	{ value: 'wf', label: 'Wallis and Futuna', currency: 'XPF' },
	{ value: 'eh', label: 'Western Sahara', currency: 'MAD' },
	{ value: 'ye', label: 'Yemen', currency: 'YER' },
	{ value: 'zm', label: 'Zambia', currency: 'ZMW' },
	{ value: 'zw', label: 'Zimbabwe', currency: 'ZWL' },
];

export const countryAndCurrency = [
	{
		country: 'Algeria',
		rate: 10,
		currency: 'dzd',
	},
	{
		country: 'France',
		rate: 1,
		currency: 'eur',
	},
];

export const wilayas = [
	{ value: 'Adrar', label: 'Adrar' },
	{ value: 'Chlef', label: 'Chlef' },
	{ value: 'Laghouat', label: 'Laghouat' },
	{ value: 'Oum El Bouaghi', label: 'Oum El Bouaghi' },
	{ value: 'Batna', label: 'Batna' },
	{ value: 'Béjaïa', label: 'Béjaïa' },
	{ value: 'Biskra', label: 'Biskra' },
	{ value: 'Béchar', label: 'Béchar' },
	{ value: 'Blida', label: 'Blida' },
	// { value: 'Bouira', label: 'Bouira' },
	{ value: 'Tamanrasset', label: 'Tamanrasset' },
	{ value: 'Tébessa', label: 'Tébessa' },
	{ value: 'Tlemcen', label: 'Tlemcen' },
	{ value: 'Tiaret', label: 'Tiaret' },
	{ value: 'Tizi Ouzou', label: 'Tizi Ouzou' },
	{ value: 'Alger', label: 'Alger' },
	{ value: 'Djelfa', label: 'Djelfa' },
	{ value: 'Jijel', label: 'Jijel' },
	{ value: 'Sétif', label: 'Sétif' },
	{ value: 'Saïda', label: 'Saïda' },
	{ value: 'Skikda', label: 'Skikda' },
	{ value: 'Sidi Bel Abbès', label: 'Sidi Bel Abbès' },
	{ value: 'Annaba', label: 'Annaba' },
	{ value: 'Guelma', label: 'Guelma' },
	{ value: 'Constantine', label: 'Constantine' },
	{ value: 'Médéa', label: 'Médéa' },
	{ value: 'Mostaganem', label: 'Mostaganem' },
	{ value: "M'Sila", label: "M'Sila" },
	{ value: 'Mascara', label: 'Mascara' },
	{ value: 'Ouargla', label: 'Ouargla' },
	{ value: 'Oran', label: 'Oran' },
	{ value: 'El Bayadh', label: 'El Bayadh' },
	{ value: 'Illizi', label: 'Illizi' },
	{ value: 'Bordj Bou Arréridj', label: 'Bordj Bou Arréridj' },
	{ value: 'Boumerdès', label: 'Boumerdès' },
	{ value: 'El Tarf', label: 'El Tarf' },
	{ value: 'Tindouf', label: 'Tindouf' },
	{ value: 'Tissemsilt', label: 'Tissemsilt' },
	{ value: 'El Oued', label: 'El Oued' },
	{ value: 'Khenchela', label: 'Khenchela' },
	{ value: 'Souk Ahras', label: 'Souk Ahras' },
	{ value: 'Tipaza', label: 'Tipaza' },
	{ value: 'Mila', label: 'Mila' },
	{ value: 'Aïn Defla', label: 'Aïn Defla' },
	{ value: 'Naâma', label: 'Naâma' },
	{ value: 'Aïn Témouchent', label: 'Aïn Témouchent' },
	{ value: 'Ghardaïa', label: 'Ghardaïa' },
	{ value: 'Relizane', label: 'Relizane' },
	{ value: 'Timimoun', label: 'Timimoun' },
	// { value: 'Bordj Badji Mokhtar', label: 'Bordj Badji Mokhtar' },
	{ value: 'Ouled Djellal', label: 'Ouled Djellal' },
	{ value: 'Béni Abbès', label: 'Béni Abbès' },
	{ value: 'In Salah', label: 'In Salah' },
	{ value: 'In Guezzam', label: 'In Guezzam' },
	{ value: 'Touggourt', label: 'Touggourt' },
	{ value: 'Djanet', label: 'Djanet' },
	{ value: "El M'Ghair", label: "El M'Ghair" },
	{ value: 'El Meniaa', label: 'El Meniaa' },
];

export const wilayasWithCommunes = [
	{
		label: 'Adrar',
		region: 'Zone 3',
		value: [
			{ 'label': 'Adrar', 'value': 'Adrar' },
			{ 'label': 'Akabli', 'value': 'Akabli' },
			{ 'label': 'Aoulef', 'value': 'Aoulef' },
			{ 'label': 'Aougrout', 'value': 'Aougrout' },
			{ 'label': 'Bouda', 'value': 'Bouda' },
			{ 'label': 'Charouine', 'value': 'Charouine' },
			{ 'label': 'Deldoul', 'value': 'Deldoul' },
			{ 'label': 'Fenoughil', 'value': 'Fenoughil' },
			{ 'label': 'Inzghmir', 'value': 'Inzghmir' },
			{ 'label': 'Ksar Kaddour', 'value': 'Ksar Kaddour' },
			{ 'label': 'Metarfa', 'value': 'Metarfa' },
			{ 'label': 'Ouled Ahmed Tammi', 'value': 'Ouled Ahmed Tammi' },
			{ 'label': 'Ouled Aïssa', 'value': 'Ouled Aïssa' },
			{ 'label': 'Ouled Saïd', 'value': 'Ouled Saïd' },
			{ 'label': 'Reggane', 'value': 'Reggane' },
			{ 'label': 'Sali', 'value': 'Sali' },
			{ 'label': 'Sebaa', 'value': 'Sebaa' },
			{ 'label': 'Tamantit', 'value': 'Tamantit' },
			{ 'label': 'Tamest', 'value': 'Tamest' },
			{ 'label': 'Tamekten', 'value': 'Tamekten' },
			{ 'label': 'Talmine', 'value': 'Talmine' },
			{ 'label': 'Timiaouine', 'value': 'Timiaouine' },
			{ 'label': 'Timimoun', 'value': 'Timimoun' },
			{ 'label': 'Tit', 'value': 'Tit' },
			{ 'label': 'Tinerkouk', 'value': 'Tinerkouk' },
			{ 'label': 'Tsabit', 'value': 'Tsabit' },
			{ 'label': 'Zaouiet Kounta', 'value': 'Zaouiet Kounta' },
		],
	},
	{
		label: 'Chlef',
		region: 'Zone 2',
		value: [
			{ label: 'Abou El Hassan', value: 'Abou El Hassan' },
			{ label: 'Aïn Merane', value: 'Aïn Merane' },
			{ label: 'Béni Bouateb', value: 'Béni Bouateb' },
			{ label: 'Béni Haoua', value: 'Béni Haoua' },
			{ label: 'Béni Rached', value: 'Béni Rached' },
			{ label: 'Boukadir', value: 'Boukadir' },
			{ label: 'Bouzeghaia', value: 'Bouzeghaia' },
			{ label: 'Breira', value: 'Breira' },
			{ label: 'Chettia', value: 'Chettia' },
			{ label: 'Chlef Centre', value: 'Chlef Centre' },
			{ label: 'Dahra', value: 'Dahra' },
			{ label: 'El Hadjadj', value: 'El Hadjadj' },
			{ label: 'El Karimia', value: 'El Karimia' },
			{ label: 'El Marsa', value: 'El Marsa' },
			{ label: 'Harchoun', value: 'Harchoun' },
			{ label: 'Harenfa', value: 'Harenfa' },
			{ label: 'Labiod Medjadja', value: 'Labiod Medjadja' },
			{ label: 'Moussadek', value: 'Moussadek' },
			{ label: 'Oued Fodda', value: 'Oued Fodda' },
			{ label: 'Oued Goussine', value: 'Oued Goussine' },
			{ label: 'Oued Sly', value: 'Oued Sly' },
			{ label: 'Ouled Abbes', value: 'Ouled Abbes' },
			{ label: 'Ouled Ben Abdelkader', value: 'Ouled Ben Abdelkader' },
			{ label: 'Ouled Fares', value: 'Ouled Fares' },
			{ label: 'Oum Drou', value: 'Oum Drou' },
			{ label: 'Sidi Abderrahmane', value: 'Sidi Abderrahmane' },
			{ label: 'Sidi Akkacha', value: 'Sidi Akkacha' },
			{ label: 'Sobha', value: 'Sobha' },
			{ label: 'Talassa', value: 'Talassa' },
			{ label: 'Tadjena', value: 'Tadjena' },
			{ label: 'Taougrite', value: 'Taougrite' },
			{ label: 'Ténès', value: 'Ténès' },
			{ label: 'Zeboudja', value: 'Zeboudja' },
		],
	},
	{
		label: 'Laghouat',
		region: 'Zone 2',
		value: [
			{ label: 'Aflou', value: 'Aflou' },
			{ label: 'Aïn Madhi', value: 'Aïn Madhi' },
			{ label: 'Aïn Sidi Ali', value: 'Aïn Sidi Ali' },
			{ label: 'Beidha', value: 'Beidha' },
			{ label: 'Bennasser Benchohra', value: 'Bennasser Benchohra' },
			{ label: 'Brida', value: 'Brida' },
			{ label: 'El Assafia', value: 'El Assafia' },
			{ label: 'El Ghicha', value: 'El Ghicha' },
			{ label: 'El Houaita', value: 'El Houaita' },
			{ label: 'Gueltat Sidi Saad', value: 'Gueltat Sidi Saad' },
			{ label: 'Hadj Mechri', value: 'Hadj Mechri' },
			{ label: 'Hassi Delaa', value: 'Hassi Delaa' },
			{ label: "Hassi R'Mel", value: "Hassi R'Mel" },
			{ label: 'Kheneg', value: 'Kheneg' },
			{ label: 'Ksar El Hirane', value: 'Ksar El Hirane' },
			{ label: 'Laghouat', value: 'Laghouat' },
			{ label: "Oued M'Zi", value: "Oued M'Zi" },
			{ label: 'Oued Morra', value: 'Oued Morra' },
			{ label: 'Sebgag', value: 'Sebgag' },
			{ label: 'Sidi Bouzid', value: 'Sidi Bouzid' },
			{ label: 'Sidi Makhlouf', value: 'Sidi Makhlouf' },
			{ label: 'Tadjrouna', value: 'Tadjrouna' },
			{ label: 'Tadjemout', value: 'Tadjemout' },
		],
	},
	{
		label: 'Oum El Bouaghi',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Babouche', value: 'Aïn Babouche' },
			{ label: 'Aïn Beïda', value: 'Aïn Beïda' },
			{ label: 'Aïn Diss', value: 'Aïn Diss' },
			{ label: 'Aïn Fakroun', value: 'Aïn Fakroun' },
			{ label: 'Aïn Kercha', value: 'Aïn Kercha' },
			{ label: "Aïn M'lila", value: "Aïn M'lila" },
			{ label: 'Aïn Zitoun', value: 'Aïn Zitoun' },
			{ label: 'Behir Chergui', value: 'Behir Chergui' },
			{ label: 'Berriche', value: 'Berriche' },
			{ label: 'Bir Chouhada', value: 'Bir Chouhada' },
			{ label: 'Dhalaa', value: 'Dhalaa' },
			{ label: 'El Amiria', value: 'El Amiria' },
			{ label: 'El Belala', value: 'El Belala' },
			{ label: 'El Djazia', value: 'El Djazia' },
			{
				label: 'El Fedjoudj Boughrara Saoudi',
				value: 'El Fedjoudj Boughrara Saoudi',
			},
			{ label: 'El Harmilia', value: 'El Harmilia' },
			{ label: 'Fkirina', value: 'Fkirina' },
			{ label: 'Hanchir Toumghani', value: 'Hanchir Toumghani' },
			{ label: 'Ksar Sbahi', value: 'Ksar Sbahi' },
			{ label: 'Meskiana', value: 'Meskiana' },
			{ label: 'Oued Nini', value: 'Oued Nini' },
			{ label: 'Ouled Gacem', value: 'Ouled Gacem' },
			{ label: 'Ouled Hamla', value: 'Ouled Hamla' },
			{ label: 'Ouled Zouaï', value: 'Ouled Zouaï' },
			{ label: 'Oum el Bouaghi', value: 'Oum el Bouaghi' },
			{ label: 'Rahia', value: 'Rahia' },
			{ label: 'Sigus', value: 'Sigus' },
			{ label: 'Souk Naamane', value: 'Souk Naamane' },
			{ label: 'Zorg', value: 'Zorg' },
		],
	},
	{
		label: 'Batna',
		region: 'Zone 2',
		value: [
			{ label: 'Abdelkader Azil', value: 'Abdelkader Azil' },
			{ label: 'Aïn Djasser', value: 'Aïn Djasser' },
			{ label: 'Aïn Touta', value: 'Aïn Touta' },
			{ label: 'Aïn Yagout', value: 'Aïn Yagout' },
			{ label: 'Arris', value: 'Arris' },
			{ label: 'Barika', value: 'Barika' },
			{
				label: 'Ben Foudhala El Hakania',
				value: 'Ben Foudhala El Hakania',
			},
			{ label: 'Bitam', value: 'Bitam' },
			{ label: 'Boumia (Batna)', value: 'Boumia (Batna)' },
			{ label: 'Boulhilat', value: 'Boulhilat' },
			{ label: 'Bouzina', value: 'Bouzina' },
			{ label: 'Boumagueur', value: 'Boumagueur' },
			{ label: 'Chemora', value: 'Chemora' },
			{ label: 'Chir', value: 'Chir' },
			{ label: 'Djezar', value: 'Djezar' },
			{ label: 'Djerma', value: 'Djerma' },
			{ label: 'El Hassi', value: 'El Hassi' },
			{ label: 'El Madher', value: 'El Madher' },
			{ label: 'Fesdis', value: 'Fesdis' },
			{ label: 'Foum Toub', value: 'Foum Toub' },
			{ label: 'Ghassira', value: 'Ghassira' },
			{ label: 'Gosbat', value: 'Gosbat' },
			{ label: 'Guigba', value: 'Guigba' },
			{ label: 'Hidoussa', value: 'Hidoussa' },
			{ label: 'Ichmoul', value: 'Ichmoul' },
			{ label: 'Inoughissen', value: 'Inoughissen' },
			{ label: 'Kimmel', value: 'Kimmel' },
			{ label: 'Ksar Bellezma', value: 'Ksar Bellezma' },
			{ label: 'Larbaâ', value: 'Larbaâ' },
			{ label: 'Lazrou', value: 'Lazrou' },
			{ label: 'Lemsane', value: 'Lemsane' },
			{ label: "M'doukel", value: "M'doukel" },
			{ label: 'Maafa', value: 'Maafa' },
			{ label: 'Menaa', value: 'Menaa' },
			{ label: 'Merouana', value: 'Merouana' },
			{ label: "N'Gaous", value: "N'Gaous" },
			{ label: 'Oued Chaaba', value: 'Oued Chaaba' },
			{ label: 'Oued El Ma', value: 'Oued El Ma' },
			{ label: 'Oued Taga', value: 'Oued Taga' },
			{ label: 'Ouyoun El Assafir', value: 'Ouyoun El Assafir' },
			{ label: 'Ouled Ammar', value: 'Ouled Ammar' },
			{ label: 'Ouled Aouf', value: 'Ouled Aouf' },
			{ label: 'Ouled Fadel', value: 'Ouled Fadel' },
			{ label: 'Ouled Sellam', value: 'Ouled Sellam' },
			{ label: 'Ouled Si Slimane', value: 'Ouled Si Slimane' },
			{ label: 'Rahbat', value: 'Rahbat' },
			{ label: 'Ras El Aioun', value: 'Ras El Aioun' },
			{ label: 'Seggana', value: 'Seggana' },
			{ label: 'Seriana', value: 'Seriana' },
			{ label: 'Sefiane', value: 'Sefiane' },
			{ label: 'Talkhamt', value: 'Talkhamt' },
			{ label: 'Taxlent', value: 'Taxlent' },
			{ label: 'Tazoult', value: 'Tazoult' },
			{ label: 'Teniet El Abed', value: 'Teniet El Abed' },
			{ label: 'Tigherghar', value: 'Tigherghar' },
			{ label: 'Tighanimine', value: 'Tighanimine' },
			{ label: 'Tilatou', value: 'Tilatou' },
			{ label: 'Timgad', value: 'Timgad' },
			{ label: "T'Kout", value: "T'Kout" },
			{ label: 'Zanat El Beida', value: 'Zanat El Beida' },
		],
	},
	{
		label: 'Béjaïa',
		region: 'Zone 2',
		value: [
			{ label: 'Aït Maouche', value: 'Aït Maouche' },
			{ label: "Aït-R'zine", value: "Aït-R'zine" },
			{ label: 'Aït-Smail', value: 'Aït-Smail' },
			{ label: 'Akbou', value: 'Akbou' },
			{ label: 'Akfadou', value: 'Akfadou' },
			{ label: 'Amalou', value: 'Amalou' },
			{ label: 'Amizour', value: 'Amizour' },
			{ label: 'Barbacha', value: 'Barbacha' },
			{ label: 'Beni Djellil', value: 'Beni Djellil' },
			{ label: 'Beni Ksila', value: 'Beni Ksila' },
			{ label: 'Beni Mellikeche', value: 'Beni Mellikeche' },
			{ label: 'Bordj Bou Arreridj', value: 'Bordj Bou Arreridj' },
			{ label: 'Bouhamza', value: 'Bouhamza' },
			{ label: 'Boukhelifa', value: 'Boukhelifa' },
			{ label: 'Chellata', value: 'Chellata' },
			{ label: 'Chemini', value: 'Chemini' },
			{ label: 'Darguina', value: 'Darguina' },
			{ label: 'Draâ El-Kaïd', value: 'Draâ El-Kaïd' },
			{ label: 'El Kseur', value: 'El Kseur' },
			{ label: 'Ferraoun', value: 'Ferraoun' },
			{ label: 'Fenaïa Ilmaten', value: 'Fenaïa Ilmaten' },
			{ label: 'Ighil Ali', value: 'Ighil Ali' },
			{ label: 'Ighram', value: 'Ighram' },
			{ label: 'Kendira', value: 'Kendira' },
			{ label: 'Kherrata', value: 'Kherrata' },
			{ label: 'Laghouat', value: 'Laghouat' },
			{ label: 'Leflaye', value: 'Leflaye' },
			{ label: "M'cisna", value: "M'cisna" },
			{ label: 'Médéa', value: 'Médéa' },
			{ label: 'Melbou', value: 'Melbou' },
			{ label: 'Mostaganem', value: 'Mostaganem' },
			{ label: 'Naâma', value: 'Naâma' },
			{ label: 'Oued Ghir', value: 'Oued Ghir' },
			{ label: 'Ouzellaguen', value: 'Ouzellaguen' },
			{ label: 'Ras El Oued', value: 'Ras El Oued' },
			{ label: 'Relizane', value: 'Relizane' },
			{ label: 'Saïda', value: 'Saïda' },
			{ label: 'Seddouk', value: 'Seddouk' },
			{ label: 'Sidi-Aïch', value: 'Sidi-Aïch' },
			{ label: 'Sidi-Ayad', value: 'Sidi-Ayad' },
			{ label: 'Souk El Ténine', value: 'Souk El Ténine' },
			{ label: 'Souk-Oufella', value: 'Souk-Oufella' },
			{ label: 'Taourirt Ighil', value: 'Taourirt Ighil' },
			{ label: 'Tamidka', value: 'Tamidka' },
			{ label: 'Tazmalt', value: 'Tazmalt' },
			{ label: 'Tichy', value: 'Tichy' },
			{ label: 'Tidjil', value: 'Tidjil' },
			{ label: 'Tiflet', value: 'Tiflet' },
			{ label: "Tizi N'Berber", value: "Tizi N'Berber" },
			{ label: 'Timezrit', value: 'Timezrit' },
			{ label: 'Toudja', value: 'Toudja' },
			{ label: 'Tinabdher', value: 'Tinabdher' },
			{ label: 'Tibane', value: 'Tibane' },
			{ label: 'Tafraoui', value: 'Tafraoui' },
			{ label: 'Taskriout', value: 'Taskriout' },
		],
	},
	{
		label: 'Biskra',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Naga', value: 'Aïn Naga' },
			{ label: 'Aïn Zaatout', value: 'Aïn Zaatout' },
			{ label: 'Biskra', value: 'Biskra' },
			{ label: 'Bouchagroune', value: 'Bouchagroune' },
			{ label: 'Bordj Ben Azzouz', value: 'Bordj Ben Azzouz' },
			{ label: 'Branis', value: 'Branis' },
			{ label: 'Chetma', value: 'Chetma' },
			{ label: 'Djemorah', value: 'Djemorah' },
			{ label: 'El Feidh', value: 'El Feidh' },
			{ label: 'El Ghrous', value: 'El Ghrous' },
			{ label: 'El Hadjeb', value: 'El Hadjeb' },
			{ label: 'El Kantara', value: 'El Kantara' },
			{ label: 'El Haouch', value: 'El Haouch' },
			{ label: 'El Mizaraa', value: 'El Mizaraa' },
			{ label: 'El Outaya (Loutaya)', value: 'El Outaya (Loutaya)' },
			{ label: 'Foughala', value: 'Foughala' },
			{ label: 'Khenguet Sidi Nadji', value: 'Khenguet Sidi Nadji' },
			{ label: 'Lioua', value: 'Lioua' },
			{ label: 'Lichana', value: 'Lichana' },
			{ label: "M'Chouneche", value: "M'Chouneche" },
			{ label: 'Mekhadma', value: 'Mekhadma' },
			{ label: "M'Lili", value: "M'Lili" },
			{ label: 'Ourlal', value: 'Ourlal' },
			{ label: 'Oumache', value: 'Oumache' },
			{ label: 'Sidi Okba', value: 'Sidi Okba' },
			{ label: 'Tolga', value: 'Tolga' },
			{ label: 'Zeribet El Oued', value: 'Zeribet El Oued' },
		],
	},
	{
		label: 'Béchar',
		region: 'Zone 3',
		value: [
			{ label: 'Abadla', value: 'Abadla' },
			{ label: 'Beni Abbes', value: 'Beni Abbes' },
			{ label: 'Beni Ikhlef', value: 'Beni Ikhlef' },
			{ label: 'Béchar', value: 'Béchar' },
			{ label: 'Boukais', value: 'Boukais' },
			{ label: 'El Ouata', value: 'El Ouata' },
			{ label: 'Erg Ferradj', value: 'Erg Ferradj' },
			{ label: 'Igli', value: 'Igli' },
			{ label: 'Kenadsa', value: 'Kenadsa' },
			{ label: 'Kerzaz', value: 'Kerzaz' },
			{ label: 'Ksabi', value: 'Ksabi' },
			{ label: 'Lahmar', value: 'Lahmar' },
			{
				label: 'Mechraa Houari Boumedienne',
				value: 'Mechraa Houari Boumedienne',
			},
			{ label: 'Meridja', value: 'Meridja' },
			{ label: 'Mougheul', value: 'Mougheul' },
			{ label: 'Ouled Khoudir', value: 'Ouled Khoudir' },
			{ label: 'Tabelbala', value: 'Tabelbala' },
			{ label: 'Taghit', value: 'Taghit' },
			{ label: 'Tamtert', value: 'Tamtert' },
			{ label: 'Timoudi', value: 'Timoudi' },
		],
	},
	{
		label: 'Blida',
		region: 'Zone 1',
		value: [
			{ label: 'Aïn Romana', value: 'Aïn Romana' },
			{ label: 'Beni Mered', value: 'Beni Mered' },
			{ label: 'Beni Tamou', value: 'Beni Tamou' },
			{ label: 'Benkhelil', value: 'Benkhelil' },
			{ label: 'Blida', value: 'Blida' },
			{ label: 'Bouarfa', value: 'Bouarfa' },
			{ label: 'Boufarik', value: 'Boufarik' },
			{ label: 'Bouinan', value: 'Bouinan' },
			{ label: 'Bougara', value: 'Bougara' },
			{ label: 'Chebli', value: 'Chebli' },
			{ label: 'Chiffa', value: 'Chiffa' },
			{ label: 'Chréa', value: 'Chréa' },
			{ label: 'Djebabra', value: 'Djebabra' },
			{ label: 'El Affroun', value: 'El Affroun' },
			{ label: 'Guerouaou', value: 'Guerouaou' },
			{ label: 'Hammam Melouane', value: 'Hammam Melouane' },
			{ label: 'Larbaa', value: 'Larbaa' },
			{ label: 'Meftah', value: 'Meftah' },
			{ label: 'Mouzaia', value: 'Mouzaia' },
			{ label: 'Oued Alleug', value: 'Oued Alleug' },
			{ label: 'Oued Djer', value: 'Oued Djer' },
			{ label: 'Ouled Slama', value: 'Ouled Slama' },
			{ label: 'Ouled Yaïch', value: 'Ouled Yaïch' },
			{ label: 'Soumaa', value: 'Soumaa' },
			{ label: 'Souhane', value: 'Souhane' },
		],
	},
	// {
	// 	label: 'Bouira',
	// 	region: 'Kabylie',
	// 	value: [
	// 		{ label: 'Aghbalou', value: 'Aghbalou' },
	// 		{ label: 'Aïn Bessem', value: 'Aïn Bessem' },
	// 		{ label: 'Aïn El Hadjar', value: 'Aïn El Hadjar' },
	// 		{ label: 'Aïn El Turc', value: 'Aïn El Turc' },
	// 		{ label: 'Aïn Laloui', value: 'Aïn Laloui' },
	// 		{ label: 'Aït Laziz', value: 'Aït Laziz' },
	// 		{ label: 'Aomar', value: 'Aomar' },
	// 		{ label: 'Ath Mansour', value: 'Ath Mansour' },
	// 		{ label: 'Bechloul', value: 'Bechloul' },
	// 		{ label: 'Bir Ghbalou', value: 'Bir Ghbalou' },
	// 		{ label: 'Bordj Okhriss', value: 'Bordj Okhriss' },
	// 		// { label: 'Bouira', value: 'Bouira' },
	// 		{ label: 'Bouderbala', value: 'Bouderbala' },
	// 		{ label: 'BouKram', value: 'BouKram' },
	// 		{ label: 'Chorfa', value: 'Chorfa' },
	// 		{ label: 'Dechmia', value: 'Dechmia' },
	// 		{ label: 'Djebahia', value: 'Djebahia' },
	// 		{ label: 'Dirrah', value: 'Dirrah' },
	// 		{ label: 'El Adjiba', value: 'El Adjiba' },
	// 		{ label: 'El Hakimia', value: 'El Hakimia' },
	// 		{ label: 'El Hachimia', value: 'El Hachimia' },
	// 		{ label: 'El Khabouzia', value: 'El Khabouzia' },
	// 		{ label: 'El Mokrani', value: 'El Mokrani' },
	// 		{ label: 'El Asnam', value: 'El Asnam' },
	// 		{ label: 'Guerrouma', value: 'Guerrouma' },
	// 		{ label: 'Hadjera Zerga', value: 'Hadjera Zerga' },
	// 		{ label: 'Haizer', value: 'Haizer' },
	// 		{ label: 'Kadiria', value: 'Kadiria' },
	// 		{ label: 'Lakhdaria', value: 'Lakhdaria' },
	// 		{ label: "M'Chedallah", value: "M'Chedallah" },
	// 		{ label: 'Maala', value: 'Maala' },
	// 		{ label: 'Maamora', value: 'Maamora' },
	// 		{ label: 'Mezdour', value: 'Mezdour' },
	// 		{ label: 'Oued El Berdi', value: 'Oued El Berdi' },
	// 		{ label: 'Ouled Rached', value: 'Ouled Rached' },
	// 		{ label: 'Raouraoua', value: 'Raouraoua' },
	// 		{ label: 'Ridane', value: 'Ridane' },
	// 		{ label: 'Saharidj', value: 'Saharidj' },
	// 		{ label: 'Sour El Ghouzlane', value: 'Sour El Ghouzlane' },
	// 		{ label: 'Souk El Khemis', value: 'Souk El Khemis' },
	// 		{ label: 'Taguedit', value: 'Taguedit' },
	// 		{ label: 'Taghzout', value: 'Taghzout' },
	// 		{ label: 'Zbarbar', value: 'Zbarbar' },
	// 	],
	// },
	{
		label: 'Tamanrasset',
		region: 'Zone 3',
		value: [
			{ label: 'Abalessa', value: 'Abalessa' },
			{ label: 'Idles', value: 'Idles' },
			{ label: 'In Amguel', value: 'In Amguel' },
			{ label: 'Tamanrasset', value: 'Tamanrasset' },
			{ label: 'Tazrouk', value: 'Tazrouk' },
		],
	},
	{
		label: 'Tébessa',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Zerga', value: 'Aïn Zerga' },
			{ label: 'Bedjene', value: 'Bedjene' },
			{ label: 'Bekkaria', value: 'Bekkaria' },
			{ label: 'Bir Dheb', value: 'Bir Dheb' },
			{ label: 'Bir el-Ater', value: 'Bir el-Ater' },
			{ label: 'Bir Mokkadem', value: 'Bir Mokkadem' },
			{ label: 'Boukhadra', value: 'Boukhadra' },
			{ label: 'Boulhaf Dir', value: 'Boulhaf Dir' },
			{ label: 'Cheria', value: 'Cheria' },
			{ label: 'El Aouinet', value: 'El Aouinet' },
			{ label: 'El Houidjbet', value: 'El Houidjbet' },
			{ label: 'El Ma Labiodh', value: 'El Ma Labiodh' },
			{ label: 'El Meridj', value: 'El Meridj' },
			{ label: 'El Mezeraa', value: 'El Mezeraa' },
			{ label: 'El Ogla', value: 'El Ogla' },
			{ label: 'Ferkane', value: 'Ferkane' },
			{ label: 'Guorriguer', value: 'Guorriguer' },
			{ label: 'Hammamet', value: 'Hammamet' },
			{ label: 'Morsott', value: 'Morsott' },
			{ label: 'Negrine', value: 'Negrine' },
			{ label: 'Ouem Ali', value: 'Ouem Ali' },
			{ label: 'Ogla Melha', value: 'Ogla Melha' },
			{ label: 'Ouenza', value: 'Ouenza' },
			{ label: 'Safsaf El Ouesra', value: 'Safsaf El Ouesra' },
			{ label: 'Stah Guentis', value: 'Stah Guentis' },
			{ label: 'Tébessa', value: 'Tébessa' },
			{ label: 'Tlidjene', value: 'Tlidjene' },
		],
	},
	{
		label: 'Tlemcen',
		region: 'Zone 2',
		value: [
			{ label: 'Tlemcen', value: 'Tlemcen' },
			{ label: 'Beni Mester', value: 'Beni Mester' },
			{ label: 'Aïn Tallout', value: 'Aïn Tallout' },
			{ label: 'Remchi', value: 'Remchi' },
			{ label: 'El Fehoul', value: 'El Fehoul' },
			{ label: 'Sabra', value: 'Sabra' },
			{ label: 'Ghazaouet', value: 'Ghazaouet' },
			{ label: 'Souani', value: 'Souani' },
			{ label: 'Djebala', value: 'Djebala' },
			{ label: 'El Gor', value: 'El Gor' },
			{ label: 'Oued Lakhdar', value: 'Oued Lakhdar' },
			{ label: 'Aïn Fezza', value: 'Aïn Fezza' },
			{ label: 'Ouled Mimoun', value: 'Ouled Mimoun' },
			{ label: 'Amieur', value: 'Amieur' },
			{ label: 'Aïn Youcef/Boukoura', value: 'Aïn Youcef/Boukoura' },
			{ label: 'Zenata', value: 'Zenata' },
			{ label: 'Beni Snous', value: 'Beni Snous' },
			{ label: 'Bab El Assa', value: 'Bab El Assa' },
			{ label: 'Dar Yaghmouracene', value: 'Dar Yaghmouracene' },
			{ label: 'Fellaoucene', value: 'Fellaoucene' },
			{ label: 'Azaïls', value: 'Azaïls' },
			{ label: 'Sebaa Chioukh', value: 'Sebaa Chioukh' },
			{ label: 'Terny Beni Hdiel', value: 'Terny Beni Hdiel' },
			{ label: 'Bensekrane', value: 'Bensekrane' },
			{ label: 'Aïn Nehala', value: 'Aïn Nehala' },
			{ label: 'Hennaya', value: 'Hennaya' },
			{ label: 'Maghnia', value: 'Maghnia' },
			{ label: 'Hammam Boughrara', value: 'Hammam Boughrara' },
			{ label: 'Souahlia', value: 'Souahlia' },
			{ label: 'MSirda Fouaga', value: 'MSirda Fouaga' },
			{ label: 'Aïn Fetah', value: 'Aïn Fetah' },
			{ label: 'El Aricha', value: 'El Aricha' },
			{ label: 'Souk Tlata', value: 'Souk Tlata' },
			{ label: 'Sidi Abdelli', value: 'Sidi Abdelli' },
			{ label: 'Sebdou', value: 'Sebdou' },
			{ label: 'Beni Ouarsous', value: 'Beni Ouarsous' },
			{ label: 'Sidi Medjahed', value: 'Sidi Medjahed' },
			{ label: 'Beni Boussaid', value: 'Beni Boussaid' },
			{ label: "Marsa Ben M'Hidi", value: "Marsa Ben M'Hidi" },
			{ label: 'Nedroma', value: 'Nedroma' },
			{ label: 'Sidi Djillali', value: 'Sidi Djillali' },
			{ label: 'Beni Bahdel', value: 'Beni Bahdel' },
			{ label: 'El Bouihi', value: 'El Bouihi' },
			{ label: 'Honaïne', value: 'Honaïne' },
			{ label: 'Tienet', value: 'Tienet' },
			{ label: 'Ouled Riyah', value: 'Ouled Riyah' },
			{ label: 'Bouhlou', value: 'Bouhlou' },
			{ label: 'Beni Khellad', value: 'Beni Khellad' },
			{ label: 'Aïn Ghoraba', value: 'Aïn Ghoraba' },
			{ label: 'Chetouane', value: 'Chetouane' },
			{ label: 'Mansourah', value: 'Mansourah' },
			{ label: 'Beni Semiel', value: 'Beni Semiel' },
			{ label: 'Aïn Kebira', value: 'Aïn Kebira' },
		],
	},
	{
		label: 'Tiaret',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Bouchekif', value: 'Aïn Bouchekif' },
			{ label: 'Aïn Deheb', value: 'Aïn Deheb' },
			{ label: 'Aïn El Hadid', value: 'Aïn El Hadid' },
			{ label: 'Aïn Kermes', value: 'Aïn Kermes' },
			{ label: 'Aïn Dzarit', value: 'Aïn Dzarit' },
			{ label: 'Bougara', value: 'Bougara' },
			{ label: 'Chehaima', value: 'Chehaima' },
			{ label: 'Dahmouni', value: 'Dahmouni' },
			{ label: 'Djebilet Rosfa', value: 'Djebilet Rosfa' },
			{ label: 'Djillali Ben Amar', value: 'Djillali Ben Amar' },
			{ label: 'Faidja', value: 'Faidja' },
			{ label: 'Frenda', value: 'Frenda' },
			{ label: 'Guertoufa', value: 'Guertoufa' },
			{ label: 'Hamadia', value: 'Hamadia' },
			{ label: 'Ksar Chellala', value: 'Ksar Chellala' },
			{ label: 'Madna', value: 'Madna' },
			{ label: 'Mahdia', value: 'Mahdia' },
			{ label: 'Mechraa Safa', value: 'Mechraa Safa' },
			{ label: 'Medrissa', value: 'Medrissa' },
			{ label: 'Medroussa', value: 'Medroussa' },
			{ label: 'Meghila', value: 'Meghila' },
			{ label: 'Mellakou', value: 'Mellakou' },
			{ label: 'Nadorah', value: 'Nadorah' },
			{ label: 'Naima', value: 'Naima' },
			{ label: 'Oued Lilli', value: 'Oued Lilli' },
			{ label: 'Rahouia', value: 'Rahouia' },
			{ label: 'Rechaïga', value: 'Rechaïga' },
			{ label: 'Sebaïne', value: 'Sebaïne' },
			{ label: 'Sebt', value: 'Sebt' },
			{ label: 'Serghine', value: 'Serghine' },
			{ label: 'Si Abdelghani', value: 'Si Abdelghani' },
			{ label: 'Sidi Abderahmane', value: 'Sidi Abderahmane' },
			{ label: 'Sidi Ali Mellal', value: 'Sidi Ali Mellal' },
			{ label: 'Sidi Bakhti', value: 'Sidi Bakhti' },
			{ label: 'Sidi Hosni', value: 'Sidi Hosni' },
			{ label: 'Sougueur', value: 'Sougueur' },
			{ label: 'Tagdemt', value: 'Tagdemt' },
			{ label: 'Takhemaret', value: 'Takhemaret' },
			{ label: 'Tiaret', value: 'Tiaret' },
			{ label: 'Tidda', value: 'Tidda' },
			{ label: 'Tousnina', value: 'Tousnina' },
			{
				label: 'Zmalet El Emir Abdelkader',
				value: 'Zmalet El Emir Abdelkader',
			},
		],
	},
	{
		label: 'Tizi Ouzou',
		region: 'Zone 2',
		value: [
			{ label: 'Abi Youcef', value: 'Abi Youcef' },
			{ label: 'Aghribs', value: 'Aghribs' },
			{ label: 'Agouni Gueghrane', value: 'Agouni Gueghrane' },
			{ label: 'Aïn El Hammam', value: 'Aïn El Hammam' },
			{ label: 'Aïn Zaouia', value: 'Aïn Zaouia' },
			{ label: 'Aït Aouggacha', value: 'Aït Aouggacha' },
			{ label: 'Aït Bouaddou', value: 'Aït Bouaddou' },
			{ label: 'Aït Boumahdi', value: 'Aït Boumahdi' },
			{ label: 'Aït Chafâa', value: 'Aït Chafâa' },
			{ label: 'Aït Khellili', value: 'Aït Khellili' },
			{ label: 'Aït Mahmoud', value: 'Aït Mahmoud' },
			{ label: 'Aït Oumalou', value: 'Aït Oumalou' },
			{ label: 'Aït Toudert', value: 'Aït Toudert' },
			{ label: 'Aït Yahia', value: 'Aït Yahia' },
			{ label: 'Aït Yahia Moussa', value: 'Aït Yahia Moussa' },
			{ label: 'Akbil', value: 'Akbil' },
			{ label: 'Akerrou', value: 'Akerrou' },
			{ label: 'Assi Youcef', value: 'Assi Youcef' },
			{ label: 'Azazga', value: 'Azazga' },
			{ label: 'Azeffoun', value: 'Azeffoun' },
			{ label: 'Beni Aïssi', value: 'Beni Aïssi' },
			{ label: 'Beni Douala', value: 'Beni Douala' },
			{ label: 'Beni Yenni', value: 'Beni Yenni' },
			{ label: 'Ath Zikki', value: 'Ath Zikki' },
			{ label: 'Beni Zmenzer', value: 'Beni Zmenzer' },
			{ label: 'Boghni', value: 'Boghni' },
			{ label: 'Boudjima', value: 'Boudjima' },
			{ label: 'Bounouh', value: 'Bounouh' },
			{ label: 'Bouzeguène', value: 'Bouzeguène' },
			{ label: 'Aït Aïssa Mimoun', value: 'Aït Aïssa Mimoun' },
			{ label: 'Draâ Ben Khedda', value: 'Draâ Ben Khedda' },
			{ label: 'Draâ El Mizan', value: 'Draâ El Mizan' },
			{ label: 'Freha', value: 'Freha' },
			{ label: 'Frikat', value: 'Frikat' },
			{ label: 'Iboudraren', value: 'Iboudraren' },
			{ label: 'Idjeur', value: 'Idjeur' },
			{ label: 'Iferhounène', value: 'Iferhounène' },
			{ label: 'Ifigha', value: 'Ifigha' },
			{ label: 'Iflissen', value: 'Iflissen' },
			{ label: 'Illilten', value: 'Illilten' },
			{ label: 'Illoula Oumalou', value: 'Illoula Oumalou' },
			{ label: 'Imsouhel', value: 'Imsouhel' },
			{ label: 'Irdjen', value: 'Irdjen' },
			{ label: 'Larbaâ Nath Irathen', value: 'Larbaâ Nath Irathen' },
			{ label: 'Maâtkas', value: 'Maâtkas' },
			{ label: 'Makouda', value: 'Makouda' },
			{ label: 'Mechtras', value: 'Mechtras' },
			{ label: 'Mekla', value: 'Mekla' },
			{ label: "M'Kira", value: "M'Kira" },
			{ label: 'Mizrana', value: 'Mizrana' },
			{ label: 'Ouacif', value: 'Ouacif' },
			{ label: 'Ouadhia', value: 'Ouadhia' },
			{ label: 'Ouaguenoun', value: 'Ouaguenoun' },
			{ label: 'Sidi Namane', value: 'Sidi Namane' },
			{ label: 'Souk El Thenine', value: 'Souk El Thenine' },
			{ label: 'Souamaâ', value: 'Souamaâ' },
			{ label: 'Tadmaït', value: 'Tadmaït' },
			{ label: 'Tigzirt', value: 'Tigzirt' },
			{ label: 'Timizart', value: 'Timizart' },
			{ label: 'Tirmitine', value: 'Tirmitine' },
			{ label: 'Tizi Gheniff', value: 'Tizi Gheniff' },
			{ label: "Tizi N'Tleta", value: "Tizi N'Tleta" },
			{ label: 'Tizi Ouzou', value: 'Tizi Ouzou' },
			{ label: 'Tizi Rached', value: 'Tizi Rached' },
			{ label: 'Yakouren', value: 'Yakouren' },
			{ label: 'Yatafen', value: 'Yatafen' },
			{ label: 'Zekri', value: 'Zekri' },
		],
	},
	{
		label: 'Alger',
		region: 'Zone 1',
		value: [
			{ label: 'Aïn Benian', value: 'Aïn Benian' },
			{ label: 'Aïn Taya', value: 'Aïn Taya' },
			{ label: 'Alger-Centre', value: 'Alger-Centre' },
			{ label: 'Bab Ezzouar', value: 'Bab Ezzouar' },
			{ label: 'Bab El Oued', value: 'Bab El Oued' },
			{ label: 'Bachdjerrah', value: 'Bachdjerrah' },
			{ label: 'Baba Hassen', value: 'Baba Hassen' },
			{ label: 'Baraki', value: 'Baraki' },
			{ label: 'Beni Messous', value: 'Beni Messous' },
			{ label: 'Ben Aknoun', value: 'Ben Aknoun' },
			{ label: 'Birkhadem', value: 'Birkhadem' },
			{ label: 'Bir Mourad Raïs', value: 'Bir Mourad Raïs' },
			{ label: 'Birtouta', value: 'Birtouta' },
			{ label: 'Belouizdad', value: 'Belouizdad' },
			{ label: 'Bologhine', value: 'Bologhine' },
			{ label: 'Bordj El Bahri', value: 'Bordj El Bahri' },
			{ label: 'Bordj El Kiffan', value: 'Bordj El Kiffan' },
			{ label: 'Bouzareah', value: 'Bouzareah' },
			{ label: 'Bourouba', value: 'Bourouba' },
			{ label: 'Casbah', value: 'Casbah' },
			{ label: 'Cheraga', value: 'Cheraga' },
			{ label: 'Dar El Beïda', value: 'Dar El Beïda' },
			{ label: 'Dely Ibrahim', value: 'Dely Ibrahim' },
			{ label: 'Djasr Kasentina', value: 'Djasr Kasentina' },
			{ label: 'Douera', value: 'Douera' },
			{ label: 'Draria', value: 'Draria' },
			{ label: 'El Achour', value: 'El Achour' },
			{ label: 'El Biar', value: 'El Biar' },
			{ label: 'El Harrach', value: 'El Harrach' },
			{ label: 'El Madania', value: 'El Madania' },
			{ label: 'El Magharia', value: 'El Magharia' },
			{ label: 'El Marsa', value: 'El Marsa' },
			{ label: 'El Mouradia', value: 'El Mouradia' },
			{ label: 'Hammamet', value: 'Hammamet' },
			{ label: "H'Raoua", value: "H'Raoua" },
			{ label: 'Hydra', value: 'Hydra' },
			{ label: 'Hussein Dey', value: 'Hussein Dey' },
			{ label: 'Khraicia', value: 'Khraicia' },
			{ label: 'Kouba', value: 'Kouba' },
			{ label: 'Les Eucalyptus', value: 'Les Eucalyptus' },
			{ label: 'Mahelma', value: 'Mahelma' },
			{ label: 'Mohammadia', value: 'Mohammadia' },
			{ label: 'Oued Koriche', value: 'Oued Koriche' },
			{ label: 'Oued Smar', value: 'Oued Smar' },
			{ label: 'Ouled Chebel', value: 'Ouled Chebel' },
			{ label: 'Ouled Fayet', value: 'Ouled Fayet' },
			{ label: 'Raïs Hamidou', value: 'Raïs Hamidou' },
			{ label: 'Rahmania', value: 'Rahmania' },
			{ label: 'Reghaïa', value: 'Reghaïa' },
			{ label: 'Rouïba', value: 'Rouïba' },
			{ label: 'Saoula', value: 'Saoula' },
			{ label: "Sidi M'Hamed", value: "Sidi M'Hamed" },
			{ label: 'Sidi Moussa', value: 'Sidi Moussa' },
			{ label: 'Souidania', value: 'Souidania' },
			{ label: 'Staoueli', value: 'Staoueli' },
			{ label: 'Tessala El Merdja', value: 'Tessala El Merdja' },
			{ label: 'Zeralda', value: 'Zeralda' },
		],
	},
	{
		label: 'Djelfa',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Chouhada', value: 'Aïn Chouhada' },
			{ label: 'Aïn El Ibel', value: 'Aïn El Ibel' },
			{ label: 'Aïn Feka', value: 'Aïn Feka' },
			{ label: 'Aïn Maabed', value: 'Aïn Maabed' },
			{ label: 'Aïn Oussara', value: 'Aïn Oussara' },
			{ label: 'Amourah', value: 'Amourah' },
			{ label: 'Benhar', value: 'Benhar' },
			{ label: 'Beni Yagoub', value: 'Beni Yagoub' },
			{ label: 'Birine', value: 'Birine' },
			{ label: 'Bouira Lahdab', value: 'Bouira Lahdab' },
			{ label: 'Charef', value: 'Charef' },
			{ label: 'Dar Chioukh', value: 'Dar Chioukh' },
			{ label: 'Deldoul', value: 'Deldoul' },
			{ label: 'Djelfa', value: 'Djelfa' },
			{ label: 'Douis', value: 'Douis' },
			{ label: 'El Guedid', value: 'El Guedid' },
			{ label: 'El Idrissia', value: 'El Idrissia' },
			{ label: 'El Khemis', value: 'El Khemis' },
			{ label: 'Faidh El Botma', value: 'Faidh El Botma' },
			{ label: 'Guernini', value: 'Guernini' },
			{ label: 'Guettara', value: 'Guettara' },
			{ label: 'Had-Sahary', value: 'Had-Sahary' },
			{ label: 'Hassi Bahbah', value: 'Hassi Bahbah' },
			{ label: 'Hassi El Euch', value: 'Hassi El Euch' },
			{ label: 'Hassi Fedoul', value: 'Hassi Fedoul' },
			{ label: 'Messaad', value: 'Messaad' },
			{ label: "M'Liliha", value: "M'Liliha" },
			{ label: 'Moudjebara', value: 'Moudjebara' },
			{ label: 'Oum Laadham', value: 'Oum Laadham' },
			{ label: 'Sed Rahal', value: 'Sed Rahal' },
			{ label: 'Selmana', value: 'Selmana' },
			{ label: 'Sidi Baizid', value: 'Sidi Baizid' },
			{ label: 'Sidi Ladjel', value: 'Sidi Ladjel' },
			{ label: 'Tadmit', value: 'Tadmit' },
			{ label: 'Zaafrane', value: 'Zaafrane' },
			{ label: 'Zaccar', value: 'Zaccar' },
		],
	},
	{
		label: 'Jijel',
		region: 'Zone 2',
		value: [
			{ label: 'Jijel', value: 'Jijel' },
			{ label: 'Eraguene', value: 'Eraguene' },
			{ label: 'El Aouana', value: 'El Aouana' },
			{ label: 'Ziama Mansouriah', value: 'Ziama Mansouriah' },
			{ label: 'Taher', value: 'Taher' },
			{ label: 'Emir Abdelkader', value: 'Emir Abdelkader' },
			{ label: 'Chekfa', value: 'Chekfa' },
			{ label: 'Chahna', value: 'Chahna' },
			{ label: 'El Milia', value: 'El Milia' },
			{ label: 'Sidi Maarouf', value: 'Sidi Maarouf' },
			{ label: 'Settara', value: 'Settara' },
			{ label: 'El Ancer', value: 'El Ancer' },
			{ label: 'Sidi Abdelaziz', value: 'Sidi Abdelaziz' },
			{ label: 'Kaous', value: 'Kaous' },
			{ label: 'Ghebala', value: 'Ghebala' },
			{ label: 'Bouraoui Belhadef', value: 'Bouraoui Belhadef' },
			{ label: 'Djimla', value: 'Djimla' },
			{ label: 'Selma Benziada', value: 'Selma Benziada' },
			{ label: 'Boucif Ouled Askeur', value: 'Boucif Ouled Askeur' },
			{ label: 'El Kennar Nouchfi', value: 'El Kennar Nouchfi' },
			{
				label: 'Ouled Yahia Khedrouche',
				value: 'Ouled Yahia Khedrouche',
			},
			{ label: 'Boudriaa Ben Yadjis', value: 'Boudriaa Ben Yadjis' },
			{ label: 'Kheïri Oued Adjoul', value: 'Kheïri Oued Adjoul' },
			{ label: 'Texenna', value: 'Texenna' },
			{ label: 'Djemaa Beni Habibi', value: 'Djemaa Beni Habibi' },
			{ label: 'Bordj Tahar', value: 'Bordj Tahar' },
			{ label: 'Ouled Rabah', value: 'Ouled Rabah' },
			{ label: 'Ouadjana', value: 'Ouadjana' },
		],
	},
	{
		label: 'Sétif',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Abessa', value: 'Aïn Abessa' },
			{ label: 'Aïn Arnat', value: 'Aïn Arnat' },
			{ label: 'Aïn Azel', value: 'Aïn Azel' },
			{ label: 'Aïn El Kebira', value: 'Aïn El Kebira' },
			{ label: 'Aïn Lahdjar', value: 'Aïn Lahdjar' },
			{ label: 'Aïn Legradj', value: 'Aïn Legradj' },
			{ label: 'Aïn Oulmene', value: 'Aïn Oulmene' },
			{ label: 'Aïn Roua', value: 'Aïn Roua' },
			{ label: 'Aïn Sebt', value: 'Aïn Sebt' },
			{ label: 'Aït Naoual Mezada', value: 'Aït Naoual Mezada' },
			{ label: 'Aït Tizi', value: 'Aït Tizi' },
			{ label: 'Beni Ouartilene', value: 'Beni Ouartilene' },
			{ label: 'Amoucha', value: 'Amoucha' },
			{ label: 'Babor', value: 'Babor' },
			{ label: 'Bazer Sakhra', value: 'Bazer Sakhra' },
			{ label: 'Beidha Bordj', value: 'Beidha Bordj' },
			{ label: 'Belaa', value: 'Belaa' },
			{ label: 'Beni Aziz', value: 'Beni Aziz' },
			{ label: 'Beni Chebana', value: 'Beni Chebana' },
			{ label: 'Beni Fouda', value: 'Beni Fouda' },
			{ label: 'Beni Hocine', value: 'Beni Hocine' },
			{ label: 'Beni Mouhli', value: 'Beni Mouhli' },
			{ label: 'Bir El Arch', value: 'Bir El Arch' },
			{ label: 'Bir Haddada', value: 'Bir Haddada' },
			{ label: 'Bouandas', value: 'Bouandas' },
			{ label: 'Bougaa', value: 'Bougaa' },
			{ label: 'Bousselam', value: 'Bousselam' },
			{ label: 'Boutaleb', value: 'Boutaleb' },
			{ label: 'Dehamcha', value: 'Dehamcha' },
			{ label: 'Djemila', value: 'Djemila' },
			{ label: 'Draa Kebila', value: 'Draa Kebila' },
			{ label: 'El Eulma', value: 'El Eulma' },
			{ label: 'El Ouldja', value: 'El Ouldja' },
			{ label: 'El Ouricia', value: 'El Ouricia' },
			{ label: 'Guellal', value: 'Guellal' },
			{ label: 'Guelta Zerka', value: 'Guelta Zerka' },
			{ label: 'Guenzet', value: 'Guenzet' },
			{ label: 'Guidjel', value: 'Guidjel' },
			{ label: 'Hamma', value: 'Hamma' },
			{ label: 'Hammam Guergour', value: 'Hammam Guergour' },
			{ label: 'Hammam Soukhna', value: 'Hammam Soukhna' },
			{ label: 'Harbil', value: 'Harbil' },
			{ label: 'Ksar El Abtal', value: 'Ksar El Abtal' },
			{ label: 'Maaouia', value: 'Maaouia' },
			{ label: 'Maoklane', value: 'Maoklane' },
			{ label: 'Mezloug', value: 'Mezloug' },
			{ label: 'Oued El Barad', value: 'Oued El Barad' },
			{ label: 'Ouled Addouane', value: 'Ouled Addouane' },
			{ label: 'Ouled Sabor', value: 'Ouled Sabor' },
			{ label: 'Ouled Si Ahmed', value: 'Ouled Si Ahmed' },
			{ label: 'Ouled Tebben', value: 'Ouled Tebben' },
			{ label: 'Rasfa', value: 'Rasfa' },
			{ label: 'Salah Bey', value: 'Salah Bey' },
			{ label: 'Serdj El Ghoul', value: 'Serdj El Ghoul' },
			{ label: 'Sétif', value: 'Sétif' },
			{ label: 'Tachouda', value: 'Tachouda' },
			{ label: 'Talaifacene', value: 'Talaifacene' },
			{ label: 'Taya', value: 'Taya' },
			{ label: 'Tella', value: 'Tella' },
			{ label: "Tizi N'Bechar", value: "Tizi N'Bechar" },
		],
	},
	{
		label: 'Saïda',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn El Hadjar', value: 'Aïn El Hadjar' },
			{ label: 'Aïn Sekhouna', value: 'Aïn Sekhouna' },
			{ label: 'Aïn Soltane', value: 'Aïn Soltane' },
			{ label: 'Doui Thabet', value: 'Doui Thabet' },
			{ label: 'El Hassasna', value: 'El Hassasna' },
			{ label: 'Hounet', value: 'Hounet' },
			{ label: 'Maamora', value: 'Maamora' },
			{ label: 'Moulay Larbi', value: 'Moulay Larbi' },
			{ label: 'Ouled Brahim', value: 'Ouled Brahim' },
			{ label: 'Ouled Khaled', value: 'Ouled Khaled' },
			{ label: 'Saïda', value: 'Saïda' },
			{ label: 'Sidi Ahmed', value: 'Sidi Ahmed' },
			{ label: 'Sidi Amar', value: 'Sidi Amar' },
			{ label: 'Sidi Boubekeur', value: 'Sidi Boubekeur' },
			{ label: 'Tircine', value: 'Tircine' },
			{ label: 'Youb', value: 'Youb' },
		],
	},
	{
		label: 'Skikda',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Bouziane', value: 'Aïn Bouziane' },
			{ label: 'Aïn Charchar', value: 'Aïn Charchar' },
			{ label: 'Aïn Kechra', value: 'Aïn Kechra' },
			{ label: 'Aïn Zouit', value: 'Aïn Zouit' },
			{ label: 'Azzaba', value: 'Azzaba' },
			{ label: 'Bekkouche Lakhdar', value: 'Bekkouche Lakhdar' },
			{ label: 'Bin El Ouiden', value: 'Bin El Ouiden' },
			{ label: 'Ben Azzouz', value: 'Ben Azzouz' },
			{ label: 'Beni Bechir', value: 'Beni Bechir' },
			{ label: 'Beni Oulbane', value: 'Beni Oulbane' },
			{ label: 'Beni Zid', value: 'Beni Zid' },
			{ label: 'Bouchtata', value: 'Bouchtata' },
			{ label: 'Cheraia', value: 'Cheraia' },
			{ label: 'Collo', value: 'Collo' },
			{ label: 'Djendel Saadi Mohamed', value: 'Djendel Saadi Mohamed' },
			{ label: 'El Ghedir', value: 'El Ghedir' },
			{ label: 'El Hadaiek', value: 'El Hadaiek' },
			{ label: 'El Harrouch', value: 'El Harrouch' },
			{ label: 'El Marsa', value: 'El Marsa' },
			{ label: 'Emdjez Edchich', value: 'Emdjez Edchich' },
			{ label: 'Es Sebt', value: 'Es Sebt' },
			{ label: 'Filfila', value: 'Filfila' },
			{ label: 'Hamadi Krouma', value: 'Hamadi Krouma' },
			{ label: 'Kanoua', value: 'Kanoua' },
			{ label: 'Kerkera', value: 'Kerkera' },
			{ label: 'Kheneg Mayoum', value: 'Kheneg Mayoum' },
			{ label: 'Oued Zehour', value: 'Oued Zehour' },
			{ label: 'Ouldja Boulballout', value: 'Ouldja Boulballout' },
			{ label: 'Ouled Attia', value: 'Ouled Attia' },
			{ label: 'Ouled Hbaba', value: 'Ouled Hbaba' },
			{ label: 'Oum Toub', value: 'Oum Toub' },
			{ label: 'Ramdane Djamel', value: 'Ramdane Djamel' },
			{ label: 'Salah Bouchaour', value: 'Salah Bouchaour' },
			{ label: 'Sidi Mezghiche', value: 'Sidi Mezghiche' },
			{ label: 'Skikda', value: 'Skikda' },
			{ label: 'Tamalous', value: 'Tamalous' },
			{ label: 'Zerdaza', value: 'Zerdaza' },
			{ label: 'Zitouna', value: 'Zitouna' },
		],
	},
	{
		label: 'Sidi Bel Abbès',
		region: 'Zone 2',
		value: [
			{ label: 'Amarnas', value: 'Amarnas' },
			{ label: 'Aïn Adden', value: 'Aïn Adden' },
			{ label: 'Aïn El Berd', value: 'Aïn El Berd' },
			{ label: 'Aïn Kada', value: 'Aïn Kada' },
			{ label: 'Aïn Thrid', value: 'Aïn Thrid' },
			{ label: 'Aïn Tindamine', value: 'Aïn Tindamine' },
			{ label: 'Badredine El Mokrani', value: 'Badredine El Mokrani' },
			{ label: 'Belarbi', value: 'Belarbi' },
			{ label: 'Ben Badis', value: 'Ben Badis' },
			{ label: 'Benachiba Chelia', value: 'Benachiba Chelia' },
			{ label: 'Bir El Hammam', value: 'Bir El Hammam' },
			{ label: 'Boudjebaa El Bordj', value: 'Boudjebaa El Bordj' },
			{ label: 'Boukhanafis', value: 'Boukhanafis' },
			{ label: 'Chettouane Belaila', value: 'Chettouane Belaila' },
			{ label: 'Dhaya', value: 'Dhaya' },
			{ label: 'El Haçaiba', value: 'El Haçaiba' },
			{ label: 'Hassi Dahou', value: 'Hassi Dahou' },
			{ label: 'Hassi Zehana', value: 'Hassi Zehana' },
			{ label: 'Lamtar', value: 'Lamtar' },
			{ label: "M'Cid", value: "M'Cid" },
			{ label: 'Makedra', value: 'Makedra' },
			{ label: 'Marhoum', value: 'Marhoum' },
			{ label: 'Merine', value: 'Merine' },
			{ label: 'Mezaourou', value: 'Mezaourou' },
			{ label: 'Mostefa Ben Brahim', value: 'Mostefa Ben Brahim' },
			{ label: 'Moulay Slissen', value: 'Moulay Slissen' },
			{ label: 'Oued Sebaa', value: 'Oued Sebaa' },
			{ label: 'Oued Sefioun', value: 'Oued Sefioun' },
			{ label: 'Oued Taourira', value: 'Oued Taourira' },
			{ label: 'Ras El Ma', value: 'Ras El Ma' },
			{ label: 'Redjem Demouche', value: 'Redjem Demouche' },
			{ label: 'Sehala Thaoura', value: 'Sehala Thaoura' },
			{ label: 'Sfisef', value: 'Sfisef' },
			{ label: 'Sidi Ali Benyoub', value: 'Sidi Ali Benyoub' },
			{ label: 'Sidi Ali Boussidi', value: 'Sidi Ali Boussidi' },
			{ label: 'Sidi Bel Abbes', value: 'Sidi Bel Abbes' },
			{ label: 'Sidi Brahim', value: 'Sidi Brahim' },
			{ label: 'Sidi Chaib', value: 'Sidi Chaib' },
			{ label: 'Sidi Daho des Zairs', value: 'Sidi Daho des Zairs' },
			{ label: 'Sidi Hamadouche', value: 'Sidi Hamadouche' },
			{ label: 'Sidi Khaled', value: 'Sidi Khaled' },
			{ label: 'Sidi Lahcene', value: 'Sidi Lahcene' },
			{ label: 'Sidi Yacoub', value: 'Sidi Yacoub' },
			{ label: 'Tabia', value: 'Tabia' },
			{ label: 'Tafissour', value: 'Tafissour' },
			{ label: 'Taoudmout', value: 'Taoudmout' },
			{ label: 'Teghalimet', value: 'Teghalimet' },
			{ label: 'Telagh', value: 'Telagh' },
			{ label: 'Tenira', value: 'Tenira' },
			{ label: 'Tessala', value: 'Tessala' },
			{ label: 'Tilmouni', value: 'Tilmouni' },
			{ label: 'Zerouala', value: 'Zerouala' },
		],
	},
	{
		label: 'Annaba',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Berda', value: 'Aïn Berda' },
			{ label: 'Annaba', value: 'Annaba' },
			{ label: 'Berrahal', value: 'Berrahal' },
			{ label: 'Chetaïbi', value: 'Chetaïbi' },
			{ label: 'Cheurfa', value: 'Cheurfa' },
			{ label: 'El Bouni', value: 'El Bouni' },
			{ label: 'El Hadjar', value: 'El Hadjar' },
			{ label: 'Eulma', value: 'Eulma' },
			{ label: 'Oued El Aneb', value: 'Oued El Aneb' },
			{ label: 'Seraïdi', value: 'Seraïdi' },
			{ label: 'Sidi Amar', value: 'Sidi Amar' },
			{ label: 'Treat', value: 'Treat' },
		],
	},
	{
		label: 'Guelma',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Ben Beida', value: 'Aïn Ben Beida' },
			{ label: 'Aïn Larbi', value: 'Aïn Larbi' },
			{ label: 'Aïn Makhlouf', value: 'Aïn Makhlouf' },
			{ label: 'Aïn Reggada', value: 'Aïn Reggada' },
			{ label: 'Aïn Sandel', value: 'Aïn Sandel' },
			{ label: 'Belkheir', value: 'Belkheir' },
			{ label: 'Ben Djerrah', value: 'Ben Djerrah' },
			{ label: 'Beni Mezline', value: 'Beni Mezline' },
			{ label: 'Bordj Sabath', value: 'Bordj Sabath' },
			{ label: 'Bouhachana', value: 'Bouhachana' },
			{ label: 'Bouhamdane', value: 'Bouhamdane' },
			{ label: 'Bouati Mahmoud', value: 'Bouati Mahmoud' },
			{ label: 'Bouchegouf', value: 'Bouchegouf' },
			{ label: 'Boumahra Ahmed', value: 'Boumahra Ahmed' },
			{ label: 'Dahouara', value: 'Dahouara' },
			{ label: 'Djeballah Khemissi', value: 'Djeballah Khemissi' },
			{ label: 'El Fedjoudj', value: 'El Fedjoudj' },
			{ label: 'Guellat Bou Sbaa', value: 'Guellat Bou Sbaa' },
			{ label: 'Guelma', value: 'Guelma' },
			{ label: 'Hammam Debagh', value: 'Hammam Debagh' },
			{ label: "Hammam N'Bail", value: "Hammam N'Bail" },
			{ label: 'Héliopolis', value: 'Héliopolis' },
			{ label: 'Houari Boumédiène', value: 'Houari Boumédiène' },
			{ label: 'Khezarra', value: 'Khezarra' },
			{ label: 'Medjez Amar', value: 'Medjez Amar' },
			{ label: 'Medjez Sfa', value: 'Medjez Sfa' },
			{ label: 'Nechmaya', value: 'Nechmaya' },
			{ label: 'Oued Cheham', value: 'Oued Cheham' },
			{ label: 'Oued Fragha', value: 'Oued Fragha' },
			{ label: 'Oued Zenati', value: 'Oued Zenati' },
			{ label: 'Ras El Agba', value: 'Ras El Agba' },
			{ label: 'Roknia', value: 'Roknia' },
			{ label: 'Sellaoua Announa', value: 'Sellaoua Announa' },
			{ label: 'Tamlouka', value: 'Tamlouka' },
		],
	},
	{
		label: 'Constantine',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Abid', value: 'Aïn Abid' },
			{ label: 'Aïn Smara', value: 'Aïn Smara' },
			{ label: 'Beni Hamiden', value: 'Beni Hamiden' },
			{ label: 'Constantine', value: 'Constantine' },
			{ label: 'Didouche Mourad', value: 'Didouche Mourad' },
			{ label: 'El Khroub', value: 'El Khroub' },
			{ label: 'Hamma Bouziane', value: 'Hamma Bouziane' },
			{ label: 'Ibn Badis', value: 'Ibn Badis' },
			{ label: 'Ibn Ziad', value: 'Ibn Ziad' },
			{ label: 'Messaoud Boudjriou', value: 'Messaoud Boudjriou' },
			{ label: 'Ouled Rahmoune', value: 'Ouled Rahmoune' },
			{ label: 'Zighoud Youcef', value: 'Zighoud Youcef' },
		],
	},
	{
		label: 'Médéa',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Boucif', value: 'Aïn Boucif' },
			{ label: 'Aïn Ouksir', value: 'Aïn Ouksir' },
			{ label: 'Aissaouia', value: 'Aissaouia' },
			{ label: 'Aziz', value: 'Aziz' },
			{ label: 'Baata', value: 'Baata' },
			{ label: 'Benchicao', value: 'Benchicao' },
			{ label: 'Beni Slimane', value: 'Beni Slimane' },
			{ label: 'Berrouaghia', value: 'Berrouaghia' },
			{ label: 'Bir Ben Laabed', value: 'Bir Ben Laabed' },
			{ label: 'Boghar', value: 'Boghar' },
			{ label: 'Bou Aiche', value: 'Bou Aiche' },
			{ label: 'Bouaichoune', value: 'Bouaichoune' },
			{ label: 'Bouchrahil', value: 'Bouchrahil' },
			{ label: 'Boughezoul', value: 'Boughezoul' },
			{ label: 'Bouskene', value: 'Bouskene' },
			{ label: 'Chahbounia', value: 'Chahbounia' },
			{ label: 'Chellalet El Adhaoura', value: 'Chellalet El Adhaoura' },
			{ label: 'Cheniguel', value: 'Cheniguel' },
			{ label: 'Derrag', value: 'Derrag' },
			{ label: 'Deux Bassins', value: 'Deux Bassins' },
			{ label: 'Djouab', value: 'Djouab' },
			{ label: 'Draa Essamar', value: 'Draa Essamar' },
			{ label: 'El Azizia', value: 'El Azizia' },
			{ label: 'El Guelb El Kebir', value: 'El Guelb El Kebir' },
			{ label: 'El Hamdania', value: 'El Hamdania' },
			{ label: 'El Omaria', value: 'El Omaria' },
			{ label: 'El Ouinet', value: 'El Ouinet' },
			{ label: 'Hannacha', value: 'Hannacha' },
			{ label: 'Kef Lakhdar', value: 'Kef Lakhdar' },
			{ label: 'Khams Djouamaa', value: 'Khams Djouamaa' },
			{ label: 'Ksar Boukhari', value: 'Ksar Boukhari' },
			{ label: 'Meghraoua', value: 'Meghraoua' },
			{ label: 'Médéa', value: 'Médéa' },
			{ label: 'Moudjbar', value: 'Moudjbar' },
			{ label: 'Meftaha', value: 'Meftaha' },
			{ label: 'Mezerana', value: 'Mezerana' },
			{ label: 'Mihoub', value: 'Mihoub' },
			{ label: 'Ouamri', value: 'Ouamri' },
			{ label: 'Oued Harbil', value: 'Oued Harbil' },
			{ label: 'Ouled Antar', value: 'Ouled Antar' },
			{ label: 'Ouled Bouachra', value: 'Ouled Bouachra' },
			{ label: 'Ouled Brahim', value: 'Ouled Brahim' },
			{ label: 'Ouled Deide', value: 'Ouled Deide' },
			{ label: 'Ouled Hellal', value: 'Ouled Hellal' },
			{ label: 'Ouled Maaref', value: 'Ouled Maaref' },
			{ label: 'Oum El Djalil', value: 'Oum El Djalil' },
			{ label: 'Ouzera', value: 'Ouzera' },
			{ label: 'Rebaia', value: 'Rebaia' },
			{ label: 'Saneg', value: 'Saneg' },
			{ label: 'Sedraia', value: 'Sedraia' },
			{ label: 'Seghouane', value: 'Seghouane' },
			{ label: 'Si Mahdjoub', value: 'Si Mahdjoub' },
			{ label: 'Sidi Damed', value: 'Sidi Damed' },
			{ label: 'Sidi Errabia', value: 'Sidi Errabia' },
			{ label: 'Sidi Naamane', value: 'Sidi Naamane' },
			{ label: 'Sidi Zahar', value: 'Sidi Zahar' },
			{ label: 'Sidi Ziane', value: 'Sidi Ziane' },
			{ label: 'Souagui', value: 'Souagui' },
			{ label: 'Tablat', value: 'Tablat' },
			{ label: 'Tafraout', value: 'Tafraout' },
			{ label: 'Tamesguida', value: 'Tamesguida' },
			{ label: 'Tizi Mahdi', value: 'Tizi Mahdi' },
			{ label: 'Tlatet Eddouar', value: 'Tlatet Eddouar' },
			{ label: 'Zoubiria', value: 'Zoubiria' },
		],
	},
	{
		label: 'Mostaganem',
		region: 'Zone 2',
		value: [
			{ label: 'Abdelmalek Ramdane', value: 'Abdelmalek Ramdane' },
			{ label: 'Achaacha', value: 'Achaacha' },
			{ label: 'Aïn Boudinar', value: 'Aïn Boudinar' },
			{ label: 'Aïn Nouissy', value: 'Aïn Nouissy' },
			{ label: 'Aïn Sidi Cherif', value: 'Aïn Sidi Cherif' },
			{ label: 'Aïn Tedles', value: 'Aïn Tedles' },
			{ label: 'Blad Touahria', value: 'Blad Touahria' },
			{ label: 'Bouguirat', value: 'Bouguirat' },
			{ label: 'El Hassiane', value: 'El Hassiane' },
			{ label: 'Fornaka', value: 'Fornaka' },
			{ label: 'Hadjadj', value: 'Hadjadj' },
			{ label: 'Hassi Mameche', value: 'Hassi Mameche' },
			{ label: 'Khadra', value: 'Khadra' },
			{ label: 'Kheireddine', value: 'Kheireddine' },
			{ label: 'Mansourah', value: 'Mansourah' },
			{ label: 'Mesra', value: 'Mesra' },
			{ label: 'Mazagran', value: 'Mazagran' },
			{ label: 'Mostaganem', value: 'Mostaganem' },
			{ label: 'Nekmaria', value: 'Nekmaria' },
			{ label: 'Oued El Kheir', value: 'Oued El Kheir' },
			{ label: 'Ouled Boughalem', value: 'Ouled Boughalem' },
			{ label: 'Ouled Maallah', value: 'Ouled Maallah' },
			{ label: 'Safsaf', value: 'Safsaf' },
			{ label: 'Sayada', value: 'Sayada' },
			{ label: 'Sidi Ali', value: 'Sidi Ali' },
			{ label: 'Sidi Belattar', value: 'Sidi Belattar' },
			{ label: 'Sidi Lakhdar', value: 'Sidi Lakhdar' },
			{ label: 'Sirat', value: 'Sirat' },
			{ label: 'Souaflia', value: 'Souaflia' },
			{ label: 'Sour', value: 'Sour' },
			{ label: 'Stidia', value: 'Stidia' },
			{ label: 'Tazgait', value: 'Tazgait' },
		],
	},
	{
		label: "M'Sila",
		region: 'Zone 2',
		value: [
			{ label: 'Aïn El Hadjel', value: 'Aïn El Hadjel' },
			{ label: 'Aïn El Melh', value: 'Aïn El Melh' },
			{ label: 'Aïn Errich', value: 'Aïn Errich' },
			{ label: 'Aïn Fares', value: 'Aïn Fares' },
			{ label: 'Aïn Khadra', value: 'Aïn Khadra' },
			{ label: 'Belaiba', value: 'Belaiba' },
			{ label: 'Ben Srour', value: 'Ben Srour' },
			{ label: 'Beni Ilmane', value: 'Beni Ilmane' },
			{ label: 'Benzouh', value: 'Benzouh' },
			{ label: 'Berhoum', value: 'Berhoum' },
			{ label: 'Bir Foda', value: 'Bir Foda' },
			{ label: 'Bou Saâda', value: 'Bou Saâda' },
			{ label: 'Bouti Sayah', value: 'Bouti Sayah' },
			{ label: 'Chellal', value: 'Chellal' },
			{ label: 'Dehahna', value: 'Dehahna' },
			{ label: 'Djebel Messaad', value: 'Djebel Messaad' },
			{ label: 'El Hamel', value: 'El Hamel' },
			{ label: 'El Houamed', value: 'El Houamed' },
			{ label: 'Hammam Dhalaa', value: 'Hammam Dhalaa' },
			{ label: 'Khettouti Sed El Djir', value: 'Khettouti Sed El Djir' },
			{ label: 'Khoubana', value: 'Khoubana' },
			{ label: 'Maadid', value: 'Maadid' },
			{ label: 'Maarif', value: 'Maarif' },
			{ label: 'Magra', value: 'Magra' },
			{ label: "M'Cif", value: "M'Cif" },
			{ label: 'Medjedel', value: 'Medjedel' },
			{ label: "M'Sila", value: "M'Sila" },
			{ label: "M'Tarfa", value: "M'Tarfa" },
			{ label: 'Ouanougha', value: 'Ouanougha' },
			{ label: 'Ouled Addi Guebala', value: 'Ouled Addi Guebala' },
			{ label: 'Ouled Atia', value: 'Ouled Atia' },
			{ label: 'Mohammed Boudiaf', value: 'Mohammed Boudiaf' },
			{ label: 'Ouled Derradj', value: 'Ouled Derradj' },
			{ label: 'Ouled Madhi', value: 'Ouled Madhi' },
			{ label: 'Ouled Mansour', value: 'Ouled Mansour' },
			{ label: 'Ouled Sidi Brahim', value: 'Ouled Sidi Brahim' },
			{ label: 'Ouled Slimane', value: 'Ouled Slimane' },
			{ label: 'Oultem', value: 'Oultem' },
			{ label: 'Sidi Aïssa', value: 'Sidi Aïssa' },
			{ label: 'Sidi Ameur', value: 'Sidi Ameur' },
			{ label: 'Sidi Hadjeres', value: 'Sidi Hadjeres' },
			{ label: "Sidi M'Hamed", value: "Sidi M'Hamed" },
			{ label: 'Slim', value: 'Slim' },
			{ label: 'Souamaa', value: 'Souamaa' },
			{ label: 'Tamsa', value: 'Tamsa' },
			{ label: 'Tarmount', value: 'Tarmount' },
			{ label: 'Zarzour', value: 'Zarzour' },
		],
	},
	{
		label: 'Mascara',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Fares', value: 'Aïn Fares' },
			{ label: 'Aïn Fekan', value: 'Aïn Fekan' },
			{ label: 'Aïn Ferah', value: 'Aïn Ferah' },
			{ label: 'Aïn Fras', value: 'Aïn Fras' },
			{ label: 'Alaïmia', value: 'Alaïmia' },
			{ label: 'Aouf', value: 'Aouf' },
			{ label: 'Beniane', value: 'Beniane' },
			{ label: 'Bou Hanifia', value: 'Bou Hanifia' },
			{ label: 'Bou Henni', value: 'Bou Henni' },
			{ label: 'Chorfa', value: 'Chorfa' },
			{ label: 'El Bordj', value: 'El Bordj' },
			{ label: 'El Gaada', value: 'El Gaada' },
			{ label: 'El Ghomri', value: 'El Ghomri' },
			{ label: 'El Guettana', value: 'El Guettana' },
			{ label: 'El Keurt', value: 'El Keurt' },
			{ label: 'El Menaouer', value: 'El Menaouer' },
			{ label: 'Ferraguig', value: 'Ferraguig' },
			{ label: 'Froha', value: 'Froha' },
			{ label: 'Gharrous', value: 'Gharrous' },
			{ label: 'Guerdjoum', value: 'Guerdjoum' },
			{ label: 'Ghriss', value: 'Ghriss' },
			{ label: 'Hachem', value: 'Hachem' },
			{ label: 'Hacine', value: 'Hacine' },
			{ label: 'Khalouia', value: 'Khalouia' },
			{ label: 'Makdha', value: 'Makdha' },
			{ label: 'Mamounia', value: 'Mamounia' },
			{ label: 'Maoussa', value: 'Maoussa' },
			{ label: 'Mascara', value: 'Mascara' },
			{ label: 'Matemore', value: 'Matemore' },
			{ label: 'Mocta Douz', value: 'Mocta Douz' },
			{ label: 'Mohammadia', value: 'Mohammadia' },
			{ label: 'Nesmoth', value: 'Nesmoth' },
			{ label: 'Oggaz', value: 'Oggaz' },
			{ label: 'Oued El Abtal', value: 'Oued El Abtal' },
			{ label: 'Oued Taria', value: 'Oued Taria' },
			{ label: 'Ras El Aïn Amirouche', value: 'Ras El Aïn Amirouche' },
			{ label: 'Sedjerara', value: 'Sedjerara' },
			{ label: 'Sehailia', value: 'Sehailia' },
			{ label: 'Sidi Abdeldjebar', value: 'Sidi Abdeldjebar' },
			{ label: 'Sidi Abdelmoumen', value: 'Sidi Abdelmoumen' },
			{ label: 'Sidi Kada', value: 'Sidi Kada' },
			{ label: 'Sidi Boussaid', value: 'Sidi Boussaid' },
			{ label: 'Sig', value: 'Sig' },
			{ label: 'Tighennif', value: 'Tighennif' },
			{ label: 'Tizi', value: 'Tizi' },
			{ label: 'Zahana', value: 'Zahana' },
			{ label: 'Zelmata', value: 'Zelmata' },
		],
	},
	{
		label: 'Ouargla',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Beida', value: 'Aïn Beida' },
			{ label: 'El Borma', value: 'El Borma' },
			{ label: 'Hassi Ben Abdellah', value: 'Hassi Ben Abdellah' },
			{ label: 'Hassi Messaoud', value: 'Hassi Messaoud' },
			{ label: "N'Goussa", value: "N'Goussa" },
			{ label: 'Ouargla', value: 'Ouargla' },
			{ label: 'Rouissat', value: 'Rouissat' },
			{ label: 'Sidi Khouiled', value: 'Sidi Khouiled' },
		],
	},
	{
		label: 'Oran',
		region: 'Zone 2',
		value: [
			{ label: 'Arzew', value: 'Arzew' },
			{ label: 'Aïn El Bia', value: 'Aïn El Bia' },
			{ label: 'Aïn El Kerma', value: 'Aïn El Kerma' },
			{ label: 'Aïn El Turk', value: 'Aïn El Turk' },
			{ label: 'Ben Freha', value: 'Ben Freha' },
			{ label: 'Bethioua', value: 'Bethioua' },
			{ label: 'Bir El Djir', value: 'Bir El Djir' },
			{ label: 'Boufatis', value: 'Boufatis' },
			{ label: 'Bousfer', value: 'Bousfer' },
			{ label: 'Boutlelis', value: 'Boutlelis' },
			{ label: 'El Ançor', value: 'El Ançor' },
			{ label: 'El Braya', value: 'El Braya' },
			{ label: 'El Kerma', value: 'El Kerma' },
			{ label: 'Es Senia', value: 'Es Senia' },
			{ label: 'Gdyel', value: 'Gdyel' },
			{ label: 'Hassi Ben Okba', value: 'Hassi Ben Okba' },
			{ label: 'Hassi Bounif', value: 'Hassi Bounif' },
			{ label: 'Hassi Mefsoukh', value: 'Hassi Mefsoukh' },
			{ label: 'Marsat El Hadjadj', value: 'Marsat El Hadjadj' },
			{ label: 'Mers El Kébir', value: 'Mers El Kébir' },
			{ label: 'Misserghin', value: 'Misserghin' },
			{ label: 'Oran centre', value: 'Oran centre' },
			{ label: 'Oued Tlelat', value: 'Oued Tlelat' },
			{ label: 'Sidi Benyebka', value: 'Sidi Benyebka' },
			{ label: 'Sidi Chami', value: 'Sidi Chami' },
			{ label: 'Tafraoui', value: 'Tafraoui' },
		],
	},
	{
		label: 'El Bayadh',
		region: 'Zone 3',
		value: [
			{ label: 'Aïn El Orak', value: 'Aïn El Orak' },
			{ label: 'Arbaouat', value: 'Arbaouat' },
			{ label: 'Brezina', value: 'Brezina' },
			{ label: 'Boussemghoun', value: 'Boussemghoun' },
			{ label: 'Boualem', value: 'Boualem' },
			{ label: 'Bougtoub', value: 'Bougtoub' },
			{ label: 'Chellala', value: 'Chellala' },
			{ label: 'El Abiodh Sidi Cheikh', value: 'El Abiodh Sidi Cheikh' },
			{ label: 'El Bayadh', value: 'El Bayadh' },
			{ label: 'El Bnoud', value: 'El Bnoud' },
			{ label: 'El Kheiter', value: 'El Kheiter' },
			{ label: 'El Mehara', value: 'El Mehara' },
			{ label: 'Ghassoul', value: 'Ghassoul' },
			{ label: 'Kef El Ahmar', value: 'Kef El Ahmar' },
			{ label: 'Kraakda', value: 'Kraakda' },
			{ label: 'Rogassa', value: 'Rogassa' },
			{ label: 'Sidi Ameur', value: 'Sidi Ameur' },
			{ label: 'Sidi Slimane', value: 'Sidi Slimane' },
			{ label: 'Sidi Tifour', value: 'Sidi Tifour' },
			{ label: 'Stitten', value: 'Stitten' },
			{ label: 'Tousmouline', value: 'Tousmouline' },
			{ label: 'Cheguig', value: 'Cheguig' },
		],
	},
	{
		label: 'Illizi',
		region: 'Zone 3',
		value: [
			{ label: 'Bordj Omar Driss', value: 'Bordj Omar Driss' },
			{ label: 'Debdeb', value: 'Debdeb' },
			{ label: 'Illizi', value: 'Illizi' },
			{ label: 'In Amenas', value: 'In Amenas' },
		],
	},
	{
		label: 'Bordj Bou Arréridj',
		region: 'Zone 2',
		value: [
			{ label: 'Bordj Bou Arréridj', value: 'bordj_bou_arreridj' },
			{ label: 'El Hamadia', value: 'el_hamadia' },
			{ label: 'Aïn El Hadjar', value: 'ain_el_hadjar' },
			{ label: "M'Cheria", value: 'm_cheria' },
			{ label: 'Bordj El Kiffan', value: 'bordj_el_kiffan' },
			{ label: 'Sidi Ali', value: 'sidi_ali' },
			{ label: 'El Achour', value: 'el_achour' },
			{ label: 'Ksar el Boukhari', value: 'ksar_el_boukhari' },
			{ label: 'Bir Bouhouche', value: 'bir_bouhouche' },
			{ label: 'Bordj Tizi', value: 'bordj_tizi' },
			{ label: 'El Guerrah', value: 'el_guerrah' },
			{ label: 'Sidi Hamed', value: 'sidi_hamed' },
			{ label: "M'Sila", value: 'm_sila' },
			{ label: 'Aïn Boucif', value: 'ain_boucif' },
			{ label: 'Sidi Bouzid', value: 'sidi_bouzid' },
		],
	},
	{
		label: 'Boumerdès',
		region: 'Zone 1',
		value: [
			{ label: 'Afir', value: 'Afir' },
			{ label: 'Ammal', value: 'Ammal' },
			{ label: 'Baghlia', value: 'Baghlia' },
			{ label: 'Ben Choud', value: 'Ben Choud' },
			{ label: 'Beni Amrane', value: 'Beni Amrane' },
			{ label: 'Bordj Menaïel', value: 'Bordj Menaïel' },
			{ label: 'Boudouaou', value: 'Boudouaou' },
			{ label: 'Boudouaou El Bahri', value: 'Boudouaou El Bahri' },
			{ label: 'Boumerdès', value: 'Boumerdès' },
			{ label: 'Bouzegza Keddara', value: 'Bouzegza Keddara' },
			{ label: 'Chabet el Ameur', value: 'Chabet el Ameur' },
			{ label: 'Corso', value: 'Corso' },
			{ label: 'Dellys', value: 'Dellys' },
			{ label: 'Djinet', value: 'Djinet' },
			{ label: 'El Kharrouba', value: 'El Kharrouba' },
			{ label: 'Hammedi', value: 'Hammedi' },
			{ label: 'Isser', value: 'Isser' },
			{ label: 'Khemis El Khechna', value: 'Khemis El Khechna' },
			{ label: 'Larbatache', value: 'Larbatache' },
			{ label: 'Leghata', value: 'Leghata' },
			{ label: 'Naciria', value: 'Naciria' },
			{ label: 'Ouled Aïssa', value: 'Ouled Aïssa' },
			{ label: 'Ouled Hedadj', value: 'Ouled Hedadj' },
			{ label: 'Ouled Moussa', value: 'Ouled Moussa' },
			{ label: 'Si Mustapha', value: 'Si Mustapha' },
			{ label: 'Sidi Daoud', value: 'Sidi Daoud' },
			{ label: 'Souk El Had', value: 'Souk El Had' },
			{ label: 'Taourga', value: 'Taourga' },
			{ label: 'Thénia', value: 'Thénia' },
			{ label: 'Tidjelabine', value: 'Tidjelabine' },
			{ label: 'Timezrit', value: 'Timezrit' },
			{ label: 'Zemmouri', value: 'Zemmouri' },
		],
	},
	{
		label: 'El Tarf',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn El Assel', value: 'Aïn El Assel' },
			{ label: 'Aïn Kerma', value: 'Aïn Kerma' },
			{ label: 'Asfour', value: 'Asfour' },
			{ label: 'Ben Mehidi', value: 'Ben Mehidi' },
			{ label: 'Berrihane', value: 'Berrihane' },
			{ label: 'Besbes', value: 'Besbes' },
			{ label: 'Bougous', value: 'Bougous' },
			{ label: 'Bouhadjar', value: 'Bouhadjar' },
			{ label: 'Bouteldja', value: 'Bouteldja' },
			{ label: 'Chebaita Mokhtar', value: 'Chebaita Mokhtar' },
			{ label: 'Chefia', value: 'Chefia' },
			{ label: 'Chihani', value: 'Chihani' },
			{ label: 'Dréan', value: 'Dréan' },
			{ label: 'Echatt', value: 'Echatt' },
			{ label: 'El Aioun', value: 'El Aioun' },
			{ label: 'El Kala', value: 'El Kala' },
			{ label: 'El Tarf', value: 'El Tarf' },
			{ label: 'Hammam Beni Salah', value: 'Hammam Beni Salah' },
			{ label: 'Lac des Oiseaux', value: 'Lac des Oiseaux' },
			{ label: 'Oued Zitoun', value: 'Oued Zitoun' },
			{ label: 'Raml Souk', value: 'Raml Souk' },
			{ label: 'Souarekh', value: 'Souarekh' },
			{ label: 'Zerizer', value: 'Zerizer' },
			{ label: 'Zitouna', value: 'Zitouna' },
		],
	},
	{
		label: 'Tindouf',
		region: 'Zone 3',
		value: [
			{ label: 'Oum el Assel', value: 'Oum el Assel' },
			{ label: 'Tindouf', value: 'Tindouf' },
		],
	},
	{
		label: 'Tissemsilt',
		region: 'Zone 2',
		value: [
			{ label: 'Ammari', value: 'Ammari' },
			{ label: 'Beni Chaib', value: 'Beni Chaib' },
			{ label: 'Beni Lahcene', value: 'Beni Lahcene' },
			{ label: 'Boucaid', value: 'Boucaid' },
			{ label: 'Bordj Bou Naama', value: 'Bordj Bou Naama' },
			{
				label: 'Bordj El Emir Abdelkader',
				value: 'Bordj El Emir Abdelkader',
			},
			{ label: 'Khemisti', value: 'Khemisti' },
			{ label: 'Larbaa', value: 'Larbaa' },
			{ label: 'Lardjem', value: 'Lardjem' },
			{ label: 'Layoune', value: 'Layoune' },
			{ label: 'Lazharia', value: 'Lazharia' },
			{ label: 'Maacem', value: 'Maacem' },
			{ label: 'Melaab', value: 'Melaab' },
			{ label: 'Ouled Bessem', value: 'Ouled Bessem' },
			{ label: 'Sidi Abed', value: 'Sidi Abed' },
			{ label: 'Sidi Boutouchent', value: 'Sidi Boutouchent' },
			{ label: 'Sidi Lantri', value: 'Sidi Lantri' },
			{ label: 'Sidi Slimane', value: 'Sidi Slimane' },
			{ label: 'Tamalaht', value: 'Tamalaht' },
			{ label: 'Theniet El Had', value: 'Theniet El Had' },
			{ label: 'Tissemsilt', value: 'Tissemsilt' },
			{ label: 'Youssoufia', value: 'Youssoufia' },
		],
	},
	{
		label: 'El Oued',
		region: 'Zone 2',
		value: [
			{ label: 'El Oued', value: 'El Oued' },
			{ label: 'Robbah', value: 'Robbah' },
			{ label: 'Oued El Alenda', value: 'Oued El Alenda' },
			{ label: 'Bayadha', value: 'Bayadha' },
			{ label: 'Nakhla', value: 'Nakhla' },
			{ label: 'Guemar', value: 'Guemar' },
			{ label: 'Kouinine', value: 'Kouinine' },
			{ label: 'Reguiba', value: 'Reguiba' },
			{ label: 'Hamraia', value: 'Hamraia' },
			{ label: 'Taghzout', value: 'Taghzout' },
			{ label: 'Debila', value: 'Debila' },
			{ label: 'Hassani Abdelkrim', value: 'Hassani Abdelkrim' },
			{ label: 'Hassi Khalifa', value: 'Hassi Khalifa' },
			{ label: 'Taleb Larbi', value: 'Taleb Larbi' },
			{ label: 'Douar El Ma', value: 'Douar El Ma' },
			{ label: 'Sidi Aoun', value: 'Sidi Aoun' },
			{ label: 'Trifaoui', value: 'Trifaoui' },
			{ label: 'Magrane', value: 'Magrane' },
			{ label: 'Beni Guecha', value: 'Beni Guecha' },
			{ label: 'Ourmas', value: 'Ourmas' },
		],
	},
	{
		label: 'Khenchela',
		region: 'Zone 2',
		value: [
			{ label: 'Khenchela', value: 'Khenchela' },
			{ label: 'Babar', value: 'Babar' },
			{ label: 'Bouhmama', value: 'Bouhmama' },
			{ label: 'Chélia', value: 'Chélia' },
			{ label: "M'Sara", value: "M'Sara" },
			{ label: 'Yabous', value: 'Yabous' },
			{ label: 'Chechar', value: 'Chechar' },
			{ label: 'Djellal', value: 'Djellal' },
			{ label: 'El Oueldja', value: 'El Oueldja' },
			{ label: 'Khirane', value: 'Khirane' },
			{ label: 'Baghaï', value: 'Baghaï' },
			{ label: 'El Hamma', value: 'El Hamma' },
			{ label: 'Ensigha', value: 'Ensigha' },
			{ label: 'Tamza', value: 'Tamza' },
			{ label: 'Kais', value: 'Kais' },
			{ label: 'Remila', value: 'Remila' },
			{ label: 'Taouzient', value: 'Taouzient' },
			{ label: 'El Mahmal', value: 'El Mahmal' },
			{ label: 'Ouled Rechache', value: 'Ouled Rechache' },
			{ label: 'Aïn Touila', value: 'Aïn Touila' },
			{ label: "M'Toussa", value: "M'Toussa" },
		],
	},
	{
		label: 'Souk Ahras',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Zana', value: 'Aïn Zana' },
			{ label: 'Aïn Soltane', value: 'Aïn Soltane' },
			{ label: 'Bir Bou Haouch', value: 'Bir Bou Haouch' },
			{ label: 'Dréa', value: 'Dréa' },
			{ label: 'Hanancha', value: 'Hanancha' },
			{ label: 'Heddada', value: 'Heddada' },
			{ label: 'Khedara', value: 'Khedara' },
			{ label: 'Khemissa', value: 'Khemissa' },
			{ label: 'Mechroha', value: 'Mechroha' },
			{ label: 'Merahna', value: 'Merahna' },
			{ label: 'Ouled Driss', value: 'Ouled Driss' },
			{ label: 'Ouled Moumene', value: 'Ouled Moumene' },
			{ label: 'Oum El Adhaim', value: 'Oum El Adhaim' },
			{ label: "M'daourouch", value: "M'daourouch" },
			{ label: 'Ouillen', value: 'Ouillen' },
			{ label: 'Oued Keberit', value: 'Oued Keberit' },
			{ label: 'Sedrata', value: 'Sedrata' },
			{ label: 'Souk Ahras', value: 'Souk Ahras' },
			{ label: 'Sidi Fredj', value: 'Sidi Fredj' },
			{ label: 'Tiffech', value: 'Tiffech' },
			{ label: 'Taoura', value: 'Taoura' },
			{ label: 'Terraguelt', value: 'Terraguelt' },
			{ label: 'Ragouba', value: 'Ragouba' },
			{ label: 'Safel El Ouiden', value: 'Safel El Ouiden' },
			{ label: 'Zaarouria', value: 'Zaarouria' },
			{ label: 'Zouabi', value: 'Zouabi' },
		],
	},
	{
		label: 'Tipaza',
		region: 'Zone 1',
		value: [
			{ label: 'Tipaza', value: 'Tipaza' },
			{ label: 'Menaceur', value: 'Menaceur' },
			{ label: 'Larhat', value: 'Larhat' },
			{ label: 'Douaouda', value: 'Douaouda' },
			{ label: 'Bourkika', value: 'Bourkika' },
			{ label: 'Khemisti', value: 'Khemisti' },
			{ label: 'Aghbal', value: 'Aghbal' },
			{ label: 'Hadjout', value: 'Hadjout' },
			{ label: 'Sidi Amar', value: 'Sidi Amar' },
			{ label: 'Gouraya', value: 'Gouraya' },
			{ label: 'Nador', value: 'Nador' },
			{ label: 'Chaiba', value: 'Chaiba' },
			{ label: 'Aïn Tagourait', value: 'Aïn Tagourait' },
			{ label: 'Cherchell', value: 'Cherchell' },
			{ label: 'Damous', value: 'Damous' },
			{ label: 'Merad', value: 'Merad' },
			{ label: 'Fouka', value: 'Fouka' },
			{ label: 'Bou Ismaïl', value: 'Bou Ismaïl' },
			{ label: 'Ahmar El Aïn', value: 'Ahmar El Aïn' },
			{ label: 'Bouharoun', value: 'Bouharoun' },
			{ label: 'Sidi Ghiles', value: 'Sidi Ghiles' },
			{ label: 'Messelmoun', value: 'Messelmoun' },
			{ label: 'Sidi Rached', value: 'Sidi Rached' },
			{ label: 'Koléa', value: 'Koléa' },
			{ label: 'Attatba', value: 'Attatba' },
			{ label: 'Sidi Semiane', value: 'Sidi Semiane' },
			{ label: 'Beni Milleuk', value: 'Beni Milleuk' },
			{ label: 'Hadjeret Ennous', value: 'Hadjeret Ennous' },
		],
	},
	{
		label: 'Mila',
		region: 'Zone 2',
		value: [
			{ label: 'Ahmed Rachedi', value: 'Ahmed Rachedi' },
			{ label: 'Aïn Beida Harriche', value: 'Aïn Beida Harriche' },
			{ label: 'Aïn Mellouk', value: 'Aïn Mellouk' },
			{ label: 'Aïn Tine', value: 'Aïn Tine' },
			{ label: 'Amira Arrès', value: 'Amira Arrès' },
			{ label: 'Benyahia Abderrahmane', value: 'Benyahia Abderrahmane' },
			{ label: 'Bouhatem', value: 'Bouhatem' },
			{ label: 'Chelghoum Laid', value: 'Chelghoum Laid' },
			{ label: 'Chigara', value: 'Chigara' },
			{ label: 'Derradji Bousselah', value: 'Derradji Bousselah' },
			{ label: 'El Mechira', value: 'El Mechira' },
			{ label: 'Elayadi Barbes', value: 'Elayadi Barbes' },
			{ label: 'Ferdjioua', value: 'Ferdjioua' },
			{ label: 'Grarem Gouga', value: 'Grarem Gouga' },
			{ label: 'Hamala', value: 'Hamala' },
			{ label: 'Mila', value: 'Mila' },
			{ label: 'Minar Zarza', value: 'Minar Zarza' },
			{ label: 'Oued Athmania', value: 'Oued Athmania' },
			{ label: 'Oued Endja', value: 'Oued Endja' },
			{ label: 'Oued Seguen', value: 'Oued Seguen' },
			{ label: 'Ouled Khalouf', value: 'Ouled Khalouf' },
			{ label: 'Rouached', value: 'Rouached' },
			{ label: 'Sidi Khelifa', value: 'Sidi Khelifa' },
			{ label: 'Sidi Merouane', value: 'Sidi Merouane' },
			{ label: 'Tadjenanet', value: 'Tadjenanet' },
			{ label: 'Tassadane Haddada', value: 'Tassadane Haddada' },
			{ label: 'Teleghma', value: 'Teleghma' },
			{ label: 'Terrai Bainen', value: 'Terrai Bainen' },
			{ label: 'Tessala Lemtaï', value: 'Tessala Lemtaï' },
			{ label: 'Tiberguent', value: 'Tiberguent' },
			{ label: 'Yahia Beni Guecha', value: 'Yahia Beni Guecha' },
			{ label: 'Zeghaia', value: 'Zeghaia' },
		],
	},
	{
		label: 'Aïn Defla',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Benian', value: 'Aïn Benian' },
			{ label: 'Aïn Bouyahia', value: 'Aïn Bouyahia' },
			{ label: 'Aïn Defla', value: 'Aïn Defla' },
			{ label: 'Aïn Lechiekh', value: 'Aïn Lechiekh' },
			{ label: 'Aïn Soltane', value: 'Aïn Soltane' },
			{ label: 'Aïn Torki', value: 'Aïn Torki' },
			{ label: 'Arib', value: 'Arib' },
			{ label: 'Bathia', value: 'Bathia' },
			{ label: 'Belaas', value: 'Belaas' },
			{ label: 'Ben Allal', value: 'Ben Allal' },
			{ label: 'Birbouche', value: 'Birbouche' },
			{ label: 'Bir Ould Khelifa', value: 'Bir Ould Khelifa' },
			{ label: 'Bordj Emir Khaled', value: 'Bordj Emir Khaled' },
			{ label: 'Boumedfaa', value: 'Boumedfaa' },
			{ label: 'Bourached', value: 'Bourached' },
			{ label: 'Djelida', value: 'Djelida' },
			{ label: 'Djemaa Ouled Cheikh', value: 'Djemaa Ouled Cheikh' },
			{ label: 'Djendel', value: 'Djendel' },
			{ label: 'El Abadia', value: 'El Abadia' },
			{ label: 'El Amra', value: 'El Amra' },
			{ label: 'El Attaf', value: 'El Attaf' },
			{ label: 'El Hassania', value: 'El Hassania' },
			{ label: 'El Maine', value: 'El Maine' },
			{ label: 'Hammam Righa', value: 'Hammam Righa' },
			{ label: 'Hoceinia', value: 'Hoceinia' },
			{ label: 'Khemis Miliana', value: 'Khemis Miliana' },
			{ label: 'Mekhatria', value: 'Mekhatria' },
			{ label: 'Miliana', value: 'Miliana' },
			{ label: 'Oued Chorfa', value: 'Oued Chorfa' },
			{ label: 'Oued Djemaa', value: 'Oued Djemaa' },
		],
	},
	{
		label: 'Naâma',
		region: 'Zone 3',
		value: [
			{ label: 'Naâma', value: 'Naâma' },
			{ label: 'Mecheria', value: 'Mecheria' },
			{ label: 'Aïn Sefra', value: 'Aïn Sefra' },
			{ label: 'Tiout', value: 'Tiout' },
			{ label: 'Sfissifa', value: 'Sfissifa' },
			{ label: 'Moghrar', value: 'Moghrar' },
			{ label: 'Assela', value: 'Assela' },
			{ label: 'Djeniene Bourezg', value: 'Djeniene Bourezg' },
			{ label: 'Aïn Ben Khelil', value: 'Aïn Ben Khelil' },
			{ label: 'Makman Ben Amer', value: 'Makman Ben Amer' },
			{ label: 'Kasdir', value: 'Kasdir' },
			{ label: 'El Biod', value: 'El Biod' },
		],
	},
	{
		label: 'Aïn Témouchent',
		region: 'Zone 2',
		value: [
			{ label: 'Aghlal', value: 'Aghlal' },
			{ label: 'Aïn El Arbaa', value: 'Aïn El Arbaa' },
			{ label: 'Aïn Kihal', value: 'Aïn Kihal' },
			{ label: 'Aïn Témouchent', value: 'Aïn Témouchent' },
			{ label: 'Aïn Tolba', value: 'Aïn Tolba' },
			{ label: 'Aoubellil', value: 'Aoubellil' },
			{ label: 'Beni Saf', value: 'Beni Saf' },
			{ label: 'Bouzedjar', value: 'Bouzedjar' },
			{ label: 'Chaabat El Leham', value: 'Chaabat El Leham' },
			{ label: 'Chentouf', value: 'Chentouf' },
			{ label: 'El Amria', value: 'El Amria' },
			{ label: 'El Emir Abdelkader', value: 'El Emir Abdelkader' },
			{ label: 'El Malah', value: 'El Malah' },
			{ label: 'El Messaid', value: 'El Messaid' },
			{ label: 'Hammam Bouhadjar', value: 'Hammam Bouhadjar' },
			{ label: 'Hassasna', value: 'Hassasna' },
			{ label: 'Hassi El Ghella', value: 'Hassi El Ghella' },
			{ label: 'Oued Berkeche', value: 'Oued Berkeche' },
			{ label: 'Oued Sabah', value: 'Oued Sabah' },
			{ label: 'Ouled Boudjemaa', value: 'Ouled Boudjemaa' },
			{ label: 'Ouled Kihal', value: 'Ouled Kihal' },
			{ label: 'Oulhaça El Gheraba', value: 'Oulhaça El Gheraba' },
			{ label: 'Sidi Ben Adda', value: 'Sidi Ben Adda' },
			{ label: 'Sidi Boumedienne', value: 'Sidi Boumedienne' },
			{ label: 'Sidi Ouriache', value: 'Sidi Ouriache' },
			{ label: 'Sidi Safi', value: 'Sidi Safi' },
			{ label: 'Tamzoura', value: 'Tamzoura' },
			{ label: 'Terga', value: 'Terga' },
		],
	},
	{
		label: 'Ghardaïa',
		region: 'Zone 2',
		value: [
			{ label: 'Berriane', value: 'Berriane' },
			{ label: 'Bounoura', value: 'Bounoura' },
			{ label: 'Dhayet Bendhahoua', value: 'Dhayet Bendhahoua' },
			{ label: 'El Atteuf', value: 'El Atteuf' },
			{ label: 'El Guerrara', value: 'El Guerrara' },
			{ label: 'Ghardaïa', value: 'Ghardaïa' },
			{ label: 'Mansoura', value: 'Mansoura' },
			{ label: 'Metlili', value: 'Metlili' },
			{ label: 'Sebseb', value: 'Sebseb' },
			{ label: 'Zelfana', value: 'Zelfana' },
		],
	},
	{
		label: 'Relizane',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Rahma', value: 'Aïn Rahma' },
			{ label: 'Aïn Tarek', value: 'Aïn Tarek' },
			{ label: 'Aarch Meknassa', value: 'Aarch Meknassa' },
			{ label: 'Ammi Moussa', value: 'Ammi Moussa' },
			{ label: 'Belassel Bouzegza', value: 'Belassel Bouzegza' },
			{ label: 'Bendaoud', value: 'Bendaoud' },
			{ label: 'Beni Dergoun', value: 'Beni Dergoun' },
			{ label: 'Beni Zentis', value: 'Beni Zentis' },
			{ label: 'Dar Ben Abdellah', value: 'Dar Ben Abdellah' },
			{ label: 'Djidioua', value: 'Djidioua' },
			{ label: 'El Guettar', value: 'El Guettar' },
			{ label: 'El Hamadna', value: 'El Hamadna' },
			{ label: 'El Hassi', value: 'El Hassi' },
			{ label: 'El Matmar', value: 'El Matmar' },
			{ label: 'El Ouldja', value: 'El Ouldja' },
			{ label: 'Had Echkalla', value: 'Had Echkalla' },
			{ label: 'Hamri', value: 'Hamri' },
			{ label: 'Kalaa', value: 'Kalaa' },
			{ label: 'Lahlef', value: 'Lahlef' },
			{ label: 'Mazouna', value: 'Mazouna' },
			{ label: 'Mediouna', value: 'Mediouna' },
			{ label: 'Mendes', value: 'Mendes' },
			{ label: 'Merdja Sidi Abed', value: 'Merdja Sidi Abed' },
			{ label: 'Ouarizane', value: 'Ouarizane' },
			{ label: 'Oued Essalem', value: 'Oued Essalem' },
			{ label: 'Oued Rhiou', value: 'Oued Rhiou' },
			{ label: 'Ouled Aiche', value: 'Ouled Aiche' },
			{ label: 'Oued El Djemaa', value: 'Oued El Djemaa' },
			{ label: 'Ouled Sidi Mihoub', value: 'Ouled Sidi Mihoub' },
			{ label: 'Ramka', value: 'Ramka' },
			{ label: 'Relizane', value: 'Relizane' },
			{ label: 'Sidi Khettab', value: 'Sidi Khettab' },
			{ label: 'Sidi Lazreg', value: 'Sidi Lazreg' },
			{ label: "Sidi M'Hamed Ben Ali", value: "Sidi M'Hamed Ben Ali" },
			{ label: "Sidi M'Hamed Benaouda", value: "Sidi M'Hamed Benaouda" },
			{ label: 'Sidi Saada', value: 'Sidi Saada' },
			{ label: 'Souk El Had', value: 'Souk El Had' },
			{ label: 'Yellel', value: 'Yellel' },
			{ label: 'Zemmora', value: 'Zemmora' },
		],
	},
	{
		label: 'Timimoun',
		region: 'Zone 3',
		value: [
			{ label: 'Aougrout', value: 'Aougrout' },
			{ label: 'Charouine', value: 'Charouine' },
			{ label: 'Deldoul', value: 'Deldoul' },
			{ label: 'Ksar Kaddour', value: 'Ksar Kaddour' },
			{ label: 'Metarfa', value: 'Metarfa' },
			{ label: 'Ouled Aïssa', value: 'Ouled Aïssa' },
			{ label: 'Ouled Saïd', value: 'Ouled Saïd' },
			{ label: 'Talmine', value: 'Talmine' },
			{ label: 'Timimoun', value: 'Timimoun' },
			{ label: 'Tinerkouk', value: 'Tinerkouk' },
		],
	},
	// {
	// 	label: 'Bordj Badji Mokhtar',
	// 	region: 'Southwest',
	// 	value: [
	// 		{ label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },
	// 		{ label: 'Timiaouine', value: 'Timiaouine' },
	// 	],
	// },
	{
		label: 'Ouled Djellal',
		region: 'Zone 3',
		value: [
			{ label: 'Besbes', value: 'Besbes' },
			{ label: 'Ech Chaïba', value: 'Ech Chaïba' },
			{ label: 'Doucen', value: 'Doucen' },
			{ label: 'Ouled Djellal', value: 'Ouled Djellal' },
			{ label: 'Sidi Khaled', value: 'Sidi Khaled' },
			{ label: 'Ras El Miaad', value: 'Ras El Miaad' },
		],
	},
	{
		label: 'Béni Abbès',
		region: 'Zone 3',
		value: [
			{ label: 'Béni Abbès', value: 'Béni Abbès' },
			{ label: 'Beni Ikhlef', value: 'Beni Ikhlef' },
			{ label: 'El Ouata', value: 'El Ouata' },
			{ label: 'Igli', value: 'Igli' },
			{ label: 'Kerzaz', value: 'Kerzaz' },
			{ label: 'Ksabi', value: 'Ksabi' },
			{ label: 'Oulad Khodeir', value: 'Oulad Khodeir' },
			{ label: 'Tabelbala', value: 'Tabelbala' },
			{ label: 'Tamtert', value: 'Tamtert' },
			{ label: 'Timoudi', value: 'Timoudi' },
		],
	},
	{
		label: 'In Salah',
		region: 'Zone 3',
		value: [
			{ label: 'In Salah', value: 'In Salah' },
			{ label: 'Foggaret Ezzaouia', value: 'Foggaret Ezzaouia' },
			{ label: 'In Ghar', value: 'In Ghar' },
		],
	},
	{
		label: 'In Guezzam',
		region: 'South',
		value: [
			{ label: 'In Guezzam', value: 'In Guezzam' },
			{ label: 'Tin Zaouatine', value: 'Tin Zaouatine' },
		],
	},
	{
		label: 'Bordj Bou Arreridj',
		region: 'Zone 2',
		value: [
			{ label: 'Aïn Taghrout', value: 'Aïn Taghrout' },
			{ label: 'Aïn Tesra', value: 'Aïn Tesra' },
			{ label: 'Belimour', value: 'Belimour' },
			{ label: 'Ben Daoud', value: 'Ben Daoud' },
			{ label: 'Bir Kasdali', value: 'Bir Kasdali' },
			{ label: 'Bordj Bou Arreridj', value: 'Bordj Bou Arreridj' },
			{ label: 'Bordj Ghedir', value: 'Bordj Ghedir' },
			{ label: 'Bordj Zemoura', value: 'Bordj Zemoura' },
			{ label: 'Colla', value: 'Colla' },
			{ label: 'Djaafra', value: 'Djaafra' },
			{ label: 'El Ach', value: 'El Ach' },
			{ label: 'El Achir', value: 'El Achir' },
			{ label: 'El Anseur', value: 'El Anseur' },
			{ label: 'El Hamadia', value: 'El Hamadia' },
			{ label: 'El Main', value: 'El Main' },
			{ label: "El M'hir", value: "El M'hir" },
			{ label: 'Ghilassa', value: 'Ghilassa' },
			{ label: 'Haraza', value: 'Haraza' },
			{ label: 'Hasnaoua', value: 'Hasnaoua' },
			{ label: 'Khelil', value: 'Khelil' },
			{ label: 'Ksour', value: 'Ksour' },
			{ label: 'Mansoura', value: 'Mansoura' },
			{ label: 'Medjana', value: 'Medjana' },
			{ label: 'Ouled Brahem', value: 'Ouled Brahem' },
			{ label: 'Ouled Dahmane', value: 'Ouled Dahmane' },
			{ label: 'Ouled Sidi Brahim', value: 'Ouled Sidi Brahim' },
			{ label: 'Rabta', value: 'Rabta' },
			{ label: 'Ras El Oued', value: 'Ras El Oued' },
			{ label: 'Sidi Embarek', value: 'Sidi Embarek' },
			{ label: 'Tefreg', value: 'Tefreg' },
			{ label: 'Taglait', value: 'Taglait' },
			{ label: 'Teniet En Nasr', value: 'Teniet En Nasr' },
			{ label: 'Tassameurt', value: 'Tassameurt' },
			{ label: 'Tixter', value: 'Tixter' },
		],
	},
	{
		label: 'El Meniaa',
		region: 'Zone 3',
		value: [
			{ label: 'El Menia', value: 'El Menia' },
			{ label: 'Hassi Gara', value: 'Hassi Gara' },
			{ label: 'Hassi Fehal', value: 'Hassi Fehal' },
		],
	},
	{
		label: 'Touggourt',
		region: 'Zone 3',
		value: [
			{ label: 'Benaceur', value: 'Benaceur' },
			{ label: 'Blidet Amor', value: 'Blidet Amor' },
			{ label: 'El Allia', value: 'El Allia' },
			{ label: 'El Hadjira', value: 'El Hadjira' },
			{ label: 'Megarine', value: 'Megarine' },
			{ label: "M'Naguer", value: "M'Naguer" },
			{ label: 'Nezla', value: 'Nezla' },
			{ label: 'Sidi Slimane', value: 'Sidi Slimane' },
			{ label: 'Taibet', value: 'Taibet' },
			{ label: 'Temacine', value: 'Temacine' },
			{ label: 'Tebesbest', value: 'Tebesbest' },
			{ label: 'Touggourt', value: 'Touggourt' },
			{ label: 'Zaouia El Abidia', value: 'Zaouia El Abidia' },
		],
	},
	{
		label: 'Djanet',
		region: 'Zone 3',
		value: [
			{ label: 'Bordj El Haouas', value: 'Bordj El Haouas' },
			{ label: 'Djanet', value: 'Djanet' },
		],
	},
	{
		label: "El M'Ghair",
		region: 'Zone 3',
		value: [
			{ label: 'Djamaa', value: 'Djamaa' },
			{ label: "El M'Ghair", value: "El M'Ghair" },
			{ label: 'Merara', value: 'Merara' },
			{ label: 'Oum Touyour', value: 'Oum Touyour' },
			{ label: 'Sidi Amrane', value: 'Sidi Amrane' },
			{ label: 'Sidi Khellil', value: 'Sidi Khellil' },
			{ label: 'Still', value: 'Still' },
			{ label: 'Tendla', value: 'Tendla' },
		],
	},
	// {
	// 	label: 'El Menia',
	// 	region: 'South',
	// 	value: [
	// 		{ label: 'Mansoura', value: 'Mansoura' },
	// 		{ label: 'Hassi Fehal', value: 'Hassi Fehal' },
	// 		{ label: 'El Menia', value: 'El Menia' },
	// 		{ label: 'Hassi Gara', value: 'Hassi Gara' },
	// 	],
	// },
];

export const wilayaGroupDeliveryFee = [
	{
		wilayaGroup: 'Zone 1',
		fee: 30,
		freeShippingThreshold: 6000,
	},
	{
		wilayaGroup: 'Zone 2',
		fee: 60,
		freeShippingThreshold: 9000,
	},
	{
		wilayaGroup: 'Zone 3',
		fee: 120,
		freeShippingThreshold: 18000,
	},
];

export const getCommunes = (wilaya: string) => {
	return wilayasWithCommunes.find(data => data.label === wilaya)?.value || [];
};

export const getWilayaGroupFee = (wilaya: string, amount: number) => {
	const singleWilaya = wilayasWithCommunes.find(
		data => data.label === wilaya
	);
	const wilayaFee = wilayaGroupDeliveryFee.find(
		data => data.wilayaGroup === singleWilaya?.region
	);

	if (amount > (wilayaFee as any)?.freeShippingThreshold) {
		return 0;
	} else {
		return wilayaFee?.fee;
	}
};
