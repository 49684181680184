import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import React, { useEffect, useMemo, useState } from 'react';
import {
	Image,
	Pressable,
	StyleSheet,
	Text,
	View,
	ListRenderItem,
} from 'react-native';
import { palettes } from '../../../../config';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import DeleteIcon from '../../icons/DeleteIcon';
import { Picker } from '@react-native-picker/picker';
import { useMutation } from '@apollo/client';
import {
	REMOVE_FROM_CART,
	UPDATE_CART_QTY,
} from '../../../../graphql/cart/mutation';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { Loader } from '../../..';
import DraggableScrollView from '../../DraggableScrollView';
import { useTranslation } from 'react-i18next';
import { setUsersCart, setVisitorCart } from '../../../../redux/userSlice';
import { twoDp } from '../../../../utils/twoDP';
import getSymbolFromCurrency from 'currency-symbol-map';
import { manipulateString } from '../../../../utils/manipulateString';
import { useSetAlert } from '../../../../hooks/useSetAlerts';
import { countryAndCurrency } from '../../../../utils/countryNamesWithCodes';
import { useBrandAllowedCurrency } from '../../../../hooks/useBrandAllowedCurrency';

type Props = {
	product: CartItem;
	index: number;
	onlyBrandInCart?: TGetAllBrandsData;
	checkVariantCombinationIsAvailable: (e: boolean) => void;
	// update: any;
};

const CartProductCard = ({
	product,
	index,
	onlyBrandInCart,
	checkVariantCombinationIsAvailable,
}: Props) => {
	const { isDesktop } = useIsSpecificViewportWidth();
	const { visitorCartStore } = useAppSelector(state => state.user);
	const { isUserLoggedIn } = useAppSelector(state => state.auth);
	const [isVariantCombinationAvailable, setIsVariantCombinationAvailable] =
		useState(true);
	const cart = useAppSelector(state => state.user.cart);
	const usersCart = isUserLoggedIn ? cart : visitorCartStore || [];
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();
	const [updateQuantity] = useMutation(UPDATE_CART_QTY);
	const productImages: PopulatedImage[] = [];
	const regex = /Default/i;
	product.type === 'StarterKit' &&
		product?.starterkitId?.variants?.map(variant => {
			productImages.push(variant?.image);
		});

	const [removeItemFromCart] = useMutation(REMOVE_FROM_CART);
	const loadingCart = useAppSelector(state => state.user.loadingCart);
	const renderItem: ListRenderItem<TProductImage> = ({ item }) => {
		return <Image source={{ uri: item.src }} style={[styles.image]} />;
	};

	const variantCombination: any = () => {
		const variantWithQuantity = Object.values(
			product?.productId?.option?.variatorValues?.[0]?.variatorValues ||
				{}
		)?.find((data: any) => data?.quantity >= 1);
		return variantWithQuantity;
	};

	const [variantOneValue, setVariantOneValue] = useState(
		product?.variantType || variantCombination()?.text || ''
	);
	const [variantTwoValue, setVariantTwoValue] = useState(
		product?.associatedVariantType ||
			variantCombination()?.associatedVariant ||
			''
	);
	const [currentVariantQuantity, setCurrentVariantQuantity] = useState(
		variantCombination()?.quantity
	);
	const { isMobileView } = useIsSpecificViewportWidth();

	const { findDefaultCountry, findMyCountry, findMyCurrency } =
		useBrandAllowedCurrency({ brand: onlyBrandInCart });

	// dispatch(setVisitorCart([]));

	const updateItemQtyInState = (id: string, newQty: number) => {
		// if (!isUserLoggedIn) {
		// 	const visitorCartItemIndex = visitorCartStore.findIndex(
		// 		(data: any, itemIndex: number) => itemIndex === index
		// 	);
		// 	const cartItem = visitorCartStore.map((item, index) => {
		// 		if (index === visitorCartItemIndex) {
		// 			return {
		// 				...item,
		// 				quantity: newQty,
		// 			};
		// 		} else {
		// 			return item;
		// 		}
		// 	});

		// 	dispatch(setVisitorCart(cartItem));
		// 	return;
		// }
		const itemToUpdateIndex = usersCart.findIndex(
			item =>
				item.starterkitId?._id === id ||
				(item.productId?._id === id &&
					item.optionValue === product.optionValue)
		);

		if (isUserLoggedIn) {
			dispatch(
				setUsersCart([
					...usersCart.slice(0, itemToUpdateIndex),
					{ ...usersCart[itemToUpdateIndex], quantity: newQty },
					...usersCart.slice(itemToUpdateIndex + 1),
				])
			);
		} else {
			dispatch(
				setVisitorCart([
					...usersCart.slice(0, itemToUpdateIndex),
					{ ...usersCart[itemToUpdateIndex], quantity: newQty },
					...usersCart.slice(itemToUpdateIndex + 1),
				])
			);
		}
	};

	const updateItemPriceInState = (
		id: string,
		newPrice: string,
		variantType: string,
		associatedVariantType: string
	) => {
		const itemToUpdateIndex = usersCart.findIndex(
			item => item.starterkitId?._id === id || item.productId?._id === id
		);

		if (isUserLoggedIn) {
			dispatch(
				setUsersCart([
					...usersCart.slice(0, itemToUpdateIndex),
					{
						...usersCart[itemToUpdateIndex],
						price: +newPrice,
						variantType,
						associatedVariantType,
					},
					...usersCart.slice(itemToUpdateIndex + 1),
				])
			);
		} else {
			dispatch(
				setVisitorCart([
					...usersCart.slice(0, itemToUpdateIndex),
					{
						...usersCart[itemToUpdateIndex],
						price: +newPrice,
						variantType,
						associatedVariantType,
					},
					...usersCart.slice(itemToUpdateIndex + 1),
				])
			);
		}
	};

	const totalPrice = product?.productId
		? product?.price * product?.quantity
		: product?.starterkitId?.price * product?.quantity;

	const availableVariantCombination: any = () => {
		const variantWithQuantity = Object.values(
			product?.productId?.option?.variatorValues?.[0]?.variatorValues ||
				{}
		)?.find((data: any) => data?.quantity >= 1);
		return variantWithQuantity;
	};

	const comparePrice =
		availableVariantCombination()?.comparePrice ||
		product?.productId?.option?.values?.[0]?.compareAtPrice;

	const totalComparePrice = product?.productId
		? comparePrice * product?.quantity
		: 0;

	const avaialableQty = () => {
		if (currentVariantQuantity) {
			return +currentVariantQuantity;
		} else {
			return (
				product?.productId?.option?.inventory?.quantity ||
				product?.starterkitId?.inventory?.quantity ||
				0
			);
		}
	};

	const validQuantity = avaialableQty();

	// console.log({ validQuantity: product?.productId?.option?.inventory?.quantity, variantCombination });

	const qtyArray = Array?.from(Array(validQuantity)?.keys());
	const onCartDelete = () => {
		if (!isUserLoggedIn) {
			const visitorCartStore = usersCart.filter(
				(data: any, itemIndex: number) => itemIndex !== index
			);
			dispatch(setVisitorCart(visitorCartStore));
			return;
		}
		const cartItemId = product?.productId
			? product?.productId?._id
			: product?.starterkitId?._id;
		dispatch(
			setUsersCart(
				usersCart?.filter(item =>
					item.productId
						? item?.productId === cartItemId
							? item.productId?._id !== cartItemId &&
							  item.optionValue !== product?.optionValue
							: item.productId?._id !== cartItemId
						: item.starterkitId?._id !== cartItemId
				)
			)
		);

		removeItemFromCart({
			variables: {
				args: {
					items: product?.productId
						? {
								productId: cartItemId,
								optionValue: product?.optionValue,
						  }
						: {
								starterkitId: cartItemId,
						  },
				},
			},
		});
	};

	const getPickerValueForVariant = (variator: string) => {
		const pickerValue = Object.values(
			product?.productId?.option?.variatorValues?.find(
				data => data?.variator === variator
			)?.variatorValues
		)?.map(data => data?.text);
		return pickerValue || [];
	};

	useEffect(() => {
		if (variantOneValue || variantTwoValue) {
			const variators = product?.productId?.option?.variatorValues;
			const resultForTwoVariantCombination = Object.values(
				variators?.[0]?.variatorValues
			)?.find(
				data =>
					data?.text === variantOneValue &&
					data?.associatedVariant === variantTwoValue
			);
			const resultForOneVariant = Object.values(
				variators?.[0]?.variatorValues
			)?.find(data => data?.text === variantOneValue);

			const findVariantCombination: any =
				variators?.length > 1
					? resultForTwoVariantCombination
					: resultForOneVariant;
			if (findVariantCombination) {
				const cartItemId = product.productId
					? product.productId?._id
					: product.starterkitId._id;
				if (findVariantCombination?.quantity <= 0) {
					setIsVariantCombinationAvailable(false);
					checkVariantCombinationIsAvailable(false);
					return;
				}

				updateItemPriceInState(
					cartItemId,
					findVariantCombination?.price,
					findVariantCombination?.text,
					findVariantCombination?.associatedVariant
				);
				setCurrentVariantQuantity(+findVariantCombination?.quantity);
				checkVariantCombinationIsAvailable(true);
				setIsVariantCombinationAvailable(true);
			} else {
				setIsVariantCombinationAvailable(false);
				checkVariantCombinationIsAvailable(false);
			}
		}
	}, [variantOneValue, variantTwoValue]);
	if (loadingCart) {
		return <Loader />;
	}
	return (
		<View style={styles.flex1}>
			{!isVariantCombinationAvailable && (
				<Text style={{ color: palettes.red[0] }}>
					{t(
						'The combination you selected is currently out of stock. Please try another combination.'
					)}
				</Text>
			)}
			<View style={styles.container}>
				{product.productId ? (
					<Image
						style={isMobileView ? styles.mobileImage : styles.image}
						source={product?.productId?.image?.src}
					/>
				) : (
					<DraggableScrollView
						style={styles.image}
						data={productImages}
						showsHorizontalScrollIndicator={false}
						renderItem={renderItem as any}
					/>
				)}

				<View
					style={{
						flexDirection: 'column',
						width: '70%',
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<View>
						<View style={styles.info}>
							<Text
								numberOfLines={3}
								style={[
									styles.text,

									{
										width: '60%',
									},
								]}
							>
								{product.productId
									? product.productId?.associatedProduct?.name
									: product?.starterkitId?.name}
							</Text>
							{totalComparePrice ? (
								<Text style={styles.startingPrice}>
									{getSymbolFromCurrency(
										(findMyCurrency?.currency ||
											findDefaultCountry?.currency) as string
									)}
									{twoDp(
										totalComparePrice *
											(findMyCountry?.exchangeRate || 1)
									)}
								</Text>
							) : (
								<></>
							)}
							{/* {isDesktop && ( */}
							<Text style={styles.price}>
								{getSymbolFromCurrency(
									(findMyCurrency?.currency ||
										findDefaultCountry?.currency) as string
								)}
								{twoDp(
									totalPrice *
										(findMyCountry?.exchangeRate || 1)
								)}
							</Text>
							{/* )} */}
						</View>
						<View style={styles.info}>
							{product?.productId?.option?.values?.length &&
								product?.productId?.option?.name &&
								!product?.productId?.option?.name.match(
									regex
								) && (
									<Text style={styles.text}>
										{manipulateString.capitalize(
											product.productId.option.name
										)}
										-{product.optionValue}
									</Text>
								)}
							<Text style={styles.text}>x{product.quantity}</Text>
						</View>
					</View>
					{product?.productId?.option?.variatorValues?.map(
						(data, index) => (
							<View
								style={[
									styles.removeContainer,
									{ marginBottom: 0 },
								]}
							>
								<View style={styles.deleteContainer}>
									{/* <View
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<Text
										style={[
											styles.hoverText,
											{
												display: 'flex',
												alignItems: 'center',
												gap: 5,
											},
										]}
									>
										{data?.variator}
									</Text>
								</View> */}
									<View style={{ width: '100%' }}>
										<Picker
											style={{
												width: '100%',
												height: 20,
												borderColor: 'grey',
												borderRadius: 5,
											}}
											selectedValue={
												index === 0
													? variantOneValue
													: variantTwoValue
											}
											onValueChange={(
												itemValue,
												itemIndex
											) => {
												if (index === 0) {
													setVariantOneValue(
														itemValue
													);
												} else if (index === 1) {
													setVariantTwoValue(
														itemValue
													);
												}
											}}
										>
											{[
												...new Set(
													getPickerValueForVariant(
														data?.variator
													)
												),
											].map(val => (
												<Picker.Item
													label={val}
													value={val}
												/>
											))}
										</Picker>
									</View>
								</View>
							</View>
						)
					)}
					<View style={[styles.removeContainer, { marginTop: 5 }]}>
						<View style={styles.deleteContainer}>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								{isDesktop && (
									<Text
										style={[
											styles.hoverText,
											{
												display: 'flex',
												alignItems: 'center',
												gap: 5,
											},
										]}
										onPress={onCartDelete}
									>
										<DeleteIcon />
										{t('Remove')}
									</Text>
								)}
								{!isDesktop && (
									<Text
										style={styles.hoverText}
										onPress={onCartDelete}
									>
										<DeleteIcon />
									</Text>
								)}
							</View>
							<View>
								<Picker
									style={{
										width: 60,
										height: 30,
										borderColor: 'grey',
										borderRadius: 5,
									}}
									selectedValue={product.quantity}
									onValueChange={(itemValue, itemIndex) => {
										const cartItemId = product.productId
											? product.productId?._id
											: product.starterkitId._id;

										updateItemQtyInState(
											cartItemId,
											Number(itemValue)
										);
										const args = product.productId
											? {
													productId: cartItemId,
													quantity: Number(itemValue),
													optionValue:
														product.optionValue,
											  }
											: {
													starterkitId: cartItemId,
													quantity: Number(itemValue),
											  };
										if (isUserLoggedIn) {
											updateQuantity({
												variables: {
													args,
												},
											});
										}
									}}
								>
									{qtyArray.map(val => (
										<Picker.Item
											label={(val + 1).toString()}
											value={(val + 1).toString()}
										/>
									))}
								</Picker>
							</View>
						</View>
					</View>
					{/* <DropdownSmall/> */}
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	dropdownContainer: {
		width: 120,
	},
	flex1: {
		// flex: 1,
		marginBottom: 24,
	},
	container: {
		flexDirection: 'row',
		backgroundColor: palettes.light[0],
		borderRadius: 6,
		// height: getResponsiveStyle(122),
	},
	image: {
		height: getResponsiveStyle(122),
		width: getResponsiveStyle(112),
		marginRight: getResponsiveStyle(16),
		borderRadius: 6,
		resizeMode: 'cover',
	},

	mobileImage: {
		height: getResponsiveStyle(82),
		maxWidth: getResponsiveStyle(122),
		width: '15vw',
		marginRight: getResponsiveStyle(16),
		borderRadius: 6,
		resizeMode: 'cover',
	},
	info: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		// width: '40%',
		marginBottom: getResponsiveStyle(11),
	},
	text: {
		fontSize: getResponsiveStyle(12),
		lineHeight: getResponsiveStyle(16),
		color: palettes.dark[3],
	},
	price: {
		fontSize: getResponsiveStyle(14),
		lineHeight: getResponsiveStyle(18),
		// color: palettes.dark[0],
		color: '#F96152',
		fontWeight: 'bold',
	},
	startingPrice: {
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: 14,
		color: '#7C889B',
		marginRight: 10,
		textDecorationLine: 'line-through',
	},
	hoverText: {
		fontSize: getResponsiveStyle(12, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.red[0],
	},
	removeContainer: {
		width: '100%',
		marginBottom: getResponsiveStyle(10),
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	productDescription: {
		maxHeight: 80,
	},
	deleteContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: 11.06,
		cursor: 'pointer',
		alignItems: 'center',
		justifyContent: 'space-between',
		flex: 1,
	},
});

export default CartProductCard;
