import { useAppSelector } from '../redux/store';
import countryNamesWithCodes from '../utils/countryNamesWithCodes';

export const useBrandAllowedCurrency = ({
	brand,
}: {
	brand: TGetAllBrandsData;
}) => {
	const { country } = useAppSelector(state => state.ui);
	const findDefaultCountry = countryNamesWithCodes?.find(
		data => data?.label === brand?.address?.countryLabel
	);
	const findMyCountry = brand?.brandAllowedCountries?.find(
		data =>
			data.countryCode?.toLocaleLowerCase() ===
			country?.toLocaleLowerCase()
	);
	const findMyCurrency = countryNamesWithCodes?.find(
		data => data?.value === findMyCountry?.countryCode
	);

	return {
		findDefaultCountry,
		findMyCurrency,
		findMyCountry,
	};
};
